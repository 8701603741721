import { useDispatch, useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { Button, Input, PlusIcon, Subtitle, Title, TrashIcon } from '../shared';
import { translate } from '../../lib/lang.helper';
import { useMemo, useState } from 'react';
import { Modal, Table } from 'antd';
import { selectOysterHarvestFields } from '../../store/utils/utils.selector';
import { IUtilData } from '../../entities/utils.entities';
import { selectAccount } from '../../store/auth/auth.selector';
import { sendSingleRequest } from '../../apis';
import { updateAccountSetting } from '../../store/auth/auth.actions';
import { loadUtilsData } from '../../store/utils/utils.actions';
import { showFeedback } from '../../store/ui/ui.actions';
import ModalFeedbackView from '../shared/feedback/ModalFeedbackView';

interface IRow {
  id?: string | number;
  name: string;
  num_val?: number | null;
  type: string;
}

const columns = (lang: any) => [
  {
    title: translate(lang, 'Name'),
    dataIndex: 'name',
    key: 'name',
    render: (name: string) => <div data-name='name'>{name}</div>,
  },
  {
    title: translate(lang, 'Probability'),
    key: 'num_val',
    render: (x: IUtilData) => (
      <div data-name='num_val'>
        {x.num_val !== null && x.num_val !== undefined ? `${x.num_val} %` : '-'}
      </div>
    ),
    align: 'left',
  } as any,
];

const OysterHarvestTypes = () => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);
  const oysterFields = useSelector(selectOysterHarvestFields);
  const onGrowWaste = useSelector(selectAccount)?.oyster_crops;

  const tableData = useMemo(
    () => [
      {
        id: 'grown',
        name: translate(lang, 'Grown ons'),
        type: 'oyster_harvest',
        num_val: onGrowWaste?.grow_ons,
      },
      {
        id: 'waste',
        name: translate(lang, 'Waste'),
        type: 'oyster_harvest',
        num_val: onGrowWaste?.waste,
      },
      ...oysterFields.map(x => ({
        id: x.id,
        name: x.name,
        num_val: x.num_val,
        type: x.type,
      })),
    ],
    [onGrowWaste, oysterFields],
  );

  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<IRow[]>(tableData);
  const [disabled, setDisabled] = useState(false);

  const showError = (message: string) =>
    dispatch(
      showFeedback({
        type: 'error',
        message,
        isMessageModal: true,
      }),
    );
  const updateField = (i: number, key: 'name' | 'num_val', value: string) => {
    const val =
      key === 'name' ? value : value.length <= 0 ? null : Number(value);
    const tmp = [...data];
    tmp[i] = { ...tmp[i], [key]: val };
    setData(tmp);
  };
  const addNewField = () => {
    setData([...data, { name: '', num_val: null, type: 'oyster_harvest' }]);
  };
  const removeField = (i: number) => {
    const tmp = [...data];
    tmp.splice(i, 1);
    setData(tmp);
  };
  const confirmClick = async () => {
    if (data.some(x => !x.name || x.num_val === undefined)) {
      showError(translate(lang, 'Please fill name and probability'));
      return;
    }
    const sum = data.reduce((acc, x) => acc + (x.num_val || 0), 0);
    if (Math.abs(100 - sum) > 0.0001) {
      showError(translate(lang, 'All probability values should total to 100%'));
      return;
    }
    setDisabled(true);
    const deletedFields = oysterFields.filter(
      x => !data.some(y => y.id === x.id),
    );
    for (const d of deletedFields) {
      await sendSingleRequest({}, 'DELETE', `api/utils/${d.id}`, true);
    }
    const utils = data.filter(x => x.id !== 'grown' && x.id !== 'waste');
    const res = await sendSingleRequest(utils, 'POST', 'api/utils/bulk', true);
    if (!res.status) {
      showError(res.data?.message ?? 'Server error');
    }
    const oyster_crops = {
      ...onGrowWaste,
      grow_ons: data[0].num_val,
      waste: data[1].num_val,
    };
    const r = await dispatch(updateAccountSetting({ oyster_crops }));
    if (r !== true) {
      showError(r ?? 'Server error');
    }
    await dispatch(loadUtilsData());
    setDisabled(false);
    setVisible(false);
  };

  return (
    <div className='mt-64 harvest-type-list mb-32'>
      <div className='d-flex mb-16 align-items-center justify-content-between'>
        <Title
          className='mb-16'
          size={6}
          color='black-3'
          align='default'
          fontWeight={500}
        >
          {translate(lang, 'Oyster harvest type List')}
        </Title>
      </div>
      <div className='d-flex white-card pt-12 pl-16 mb-8'>
        <div className='d-flex align-items-center'>
          <Button
            color='blue'
            size={3}
            width='small'
            type='fill'
            onClick={() => setVisible(true)}
          >
            {translate(lang, 'Update data')}
          </Button>
        </div>
      </div>
      <Table
        rowKey='id'
        className={'table table--units table--is__cursor'}
        pagination={false}
        columns={columns(lang)}
        dataSource={data}
      />
      {visible && (
        <Modal visible={true} onCancel={() => setVisible(false)} footer={null}>
          <div className='wrap'>
            <div className='d-flex align-items-center mb-32'>
              <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
                {translate(lang, 'Add new')}
              </Subtitle>
            </div>
            <div className='section-modal'>
              <div className='section'>
                <div className='item-row'>
                  <div className='item-cell'>{translate(lang, 'Name')}</div>
                  <div className='item-cell'>
                    {translate(lang, 'Probability')}
                  </div>
                  <div className='item-cell-half'></div>
                </div>
                {data.map((row, i) => (
                  <div className='item-row' key={i}>
                    <div className='item-cell'>
                      <Input
                        label=''
                        placeholder={translate(lang, 'Name')}
                        type='text'
                        value={row.name}
                        onChange={e => updateField(i, 'name', e.target.value)}
                        disabled={row.id === 'grown' || row.id === 'waste'}
                      />
                    </div>
                    <div className='item-cell'>
                      <Input
                        label=''
                        placeholder={translate(lang, 'Probability')}
                        type='number'
                        value={row.num_val?.toString() ?? ''}
                        onChange={e =>
                          updateField(i, 'num_val', e.target.value)
                        }
                        unit='%'
                      />
                    </div>
                    <div className='item-cell-half'>
                      {row.id !== 'grown' && row.id !== 'waste' && (
                        <Button
                          width='full'
                          size={0}
                          type='bordered'
                          color='red'
                          className='d-flex justify-content-center'
                          onClick={() => removeField(i)}
                        >
                          <TrashIcon color='#cc0101' />
                        </Button>
                      )}
                    </div>
                  </div>
                ))}
                <div className='item-row'>
                  <div className='item-cell'></div>
                  <div className='item-cell'>
                    {`${data.reduce((p, c) => p + (c.num_val ?? 0), 0)} %`}
                  </div>
                  <div className='item-cell-half'>
                    <Button
                      width='full'
                      size={0}
                      type='bordered'
                      color='green'
                      className='d-flex justify-content-center'
                      onClick={addNewField}
                    >
                      <PlusIcon />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-button d-flex justify-content-end align-items-center'>
              <Button
                width={'small'}
                size={2}
                type='fill'
                color='green'
                className='rsp-btn ml-16'
                onClick={confirmClick}
                disabled={disabled}
              >
                {translate(lang, 'Confirm')}
              </Button>
            </div>
          </div>
          <ModalFeedbackView />
        </Modal>
      )}
    </div>
  );
};

export default OysterHarvestTypes;
