import {
  defaultDateFormat,
  toMillisecond,
} from '../../../../util/toggleSecondMillisecond';
import Subtitle from '../../subtitle/Subtitle';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAccount,
  selectUserMeta,
} from '../../../../store/auth/auth.selector';
import { lineSizeLabel } from '../../../../util/farmUtil';
import { Dropdown, Menu, Table } from 'antd';
import DotsIcon from '../../DotsIcon';
import { useState } from 'react';
import { sendSingleRequest } from '../../../../apis';
import { loadFarmsData } from '../../../../store/farms/farms.actions';
import { showFeedback } from '../../../../store/ui/ui.actions';
import useMenuHandler from '../useMenuHandler';
import LineFormModal from '../../../farm-modals/LineFormModal';
import { IOysterLineResource } from '../../../../entities/farms.entities';
import { compareString } from '../../../../lib/common.helpers';
import { amountDays, getLineStatus } from '../../../../lib/farm.helpers';
import { formatNumber } from '../../../../entities/util-functions';
import { selectLang } from '../../../../store/ui/ui.selector';
import { translate } from '../../../../lib/lang.helper';
import { SelectIsEditable } from '../../../../store/extra/extra.selector';
import moment from 'moment';

const toEstAmt = (line: IOysterLineResource) => {
  if (!line.growing_cycle) return null;
  if (line.growing_cycle.last_assessment?.estimated_amount) {
    return Math.round(line.growing_cycle.last_assessment.estimated_amount / 12);
  }
  return (
    line.growing_cycle.total_seeded_dozens -
    line.growing_cycle.total_harvested_dozens
  );
};

interface Props {
  linesData: IOysterLineResource[];
  farmId?: number;
  isChild?: boolean;
}

const OysterLinesTable = ({ linesData, farmId, isChild }: Props) => {
  const dispatch = useDispatch<any>();

  const lang = useSelector(selectLang);
  const userMeta = useSelector(selectUserMeta);
  const { redirectToLine } = useMenuHandler();
  const isEditable = SelectIsEditable(farmId);
  const hiddenColumns = useSelector(selectAccount)?.oyster_line_hidden_columns;

  const [editLine, setEditLine] = useState<IOysterLineResource>();

  const deleteLine = async (line: IOysterLineResource) => {
    if (!window.confirm('Are you sure you want to delete this line?')) return;
    const response = await sendSingleRequest(
      {},
      'DELETE',
      `api/farm/line/lines/${line.id}`,
      true,
    );
    if (response.status) {
      await dispatch(loadFarmsData());
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: 'Line deleted successfully',
        }),
      );
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: translate(lang, response.data?.message ?? 'Unknown error'),
        }),
      );
    }
  };
  const changeTable = async (pagination: any, filter: any, sorter: any) => {
    if (farmId) {
      const columnKey: any = sorter?.columnKey;
      const orders: any = sorter?.order;
      const data = { columnKey, orders, farmId };
      await sendSingleRequest(data, 'POST', 'api/farm/line-sorting', true);
    }
  };

  const columns = [
    {
      title: 'Line',
      key: 'line_name',
      sorter: (a: IOysterLineResource, b: IOysterLineResource) =>
        compareString(a.line_name, b.line_name),
      render: (x: IOysterLineResource) => <span>{x.line_name}</span>,
    },
    {
      title: 'Length',
      key: 'length',
      sorter: (a: IOysterLineResource, b: IOysterLineResource) =>
        a.length - b.length,
      render: (x: IOysterLineResource) => <span>{`${x.length} m`}</span>,
    },
    {
      title: 'Date seeded',
      key: 'seed_date',
      sorter: (a: IOysterLineResource, b: IOysterLineResource) => {
        if (a.growing_cycle) {
          return (
            Number(a.growing_cycle.main_seed.planned_date_seed) -
            Number(b.growing_cycle?.main_seed.planned_date_seed)
          );
        } else {
          return (a.line_idle ?? 0) - (b.line_idle ?? 0);
        }
      },
      render: (x: IOysterLineResource) =>
        x.growing_cycle ? (
          defaultDateFormat(x.growing_cycle.main_seed.planned_date_seed)
        ) : (
          <div className='d-flex flex-wrap'>
            <span className='min-width-74 pr-4'>Line empty</span>
            <Subtitle size={5} color='black' align='left' fontWeight={600}>
              {amountDays(x.line_idle)}
            </Subtitle>
          </div>
        ),
    },
    {
      title: 'Last assessment date',
      key: 'last_assessment',
      render: (line: IOysterLineResource) => {
        const x = line.growing_cycle?.last_assessment?.assessment_date;
        const d =
          getLineStatus(line, userMeta?.line_assess_expire_days) ===
          'REQUIRE_ASSESSMENT';
        return (
          <span className={d ? 'text-red' : ''}>
            {defaultDateFormat(x, '-')}
          </span>
        );
      },
    },
    {
      title: 'Avg.Size',
      key: 'condition',
      defaultSortOrder: null,
      sorter: (a: IOysterLineResource, b: IOysterLineResource) =>
        (a.growing_cycle?.last_assessment?.shell_size.avg ??
          a.growing_cycle?.main_seed.spat_size ??
          0) -
        (b.growing_cycle?.last_assessment?.shell_size.avg ??
          b.growing_cycle?.main_seed.spat_size ??
          0),
      render: (line: IOysterLineResource) =>
        line.growing_cycle ? <span>{lineSizeLabel(line)}</span> : '',
    },
    {
      title: 'Inventory',
      key: 'farming_method',
      render: (line: IOysterLineResource) => <span>{line.farming_method}</span>,
    },
    {
      title: 'Number of baskets',
      key: 'basket_count',
      sorter: (a: IOysterLineResource, b: IOysterLineResource) =>
        a.basket_count - b.basket_count,
      render: (x: IOysterLineResource) =>
        x.growing_cycle ? (
          <span>{`${x.growing_cycle.total_seeded_basket_count}/${
            x.basket_count
          } (${formatNumber(
            (x.growing_cycle.total_seeded_basket_count * 100) / x.basket_count,
          )}%)`}</span>
        ) : (
          <span>{`-/${x.basket_count}`}</span>
        ),
    },
    {
      title: 'Batch seeded',
      key: 'spat_storage',
      render: (x: IOysterLineResource) => (
        <span>{x.growing_cycle?.main_seed.spat_storage?.source ?? '-'}</span>
      ),
    },
    {
      title: 'Days growth',
      key: 'growth_days',
      render: (d: IOysterLineResource) => (
        <span>
          {d.growing_cycle
            ? amountDays(
                moment().diff(
                  toMillisecond(d.growing_cycle.main_seed.planned_date_seed),
                  'days',
                ),
              )
            : '-'}
        </span>
      ),
      sorter: (a: IOysterLineResource, b: IOysterLineResource) => {
        if (a.growing_cycle) {
          return (
            Number(a.growing_cycle.main_seed.planned_date_seed) -
            Number(b.growing_cycle?.main_seed.planned_date_seed)
          );
        } else {
          return (a.line_idle ?? 0) - (b.line_idle ?? 0);
        }
      },
    },
    {
      title: 'Est amount',
      key: 'est_amount',
      render: (d: IOysterLineResource) => {
        const estAmt = toEstAmt(d);
        return <span>{estAmt ? `${formatNumber(estAmt)} dz` : '-'}</span>;
      },
      sorter: (a: IOysterLineResource, b: IOysterLineResource) =>
        (toEstAmt(a) ?? 0) - (toEstAmt(b) ?? 0),
    },
    {
      title: '',
      key: 'more',
      render: (d: IOysterLineResource) => (
        <div>
          {isEditable && (
            <>
              <div className='dropdown' onClick={e => e.stopPropagation()}>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item onClick={() => setEditLine(d)}>
                        {translate(lang, 'Edit')}
                      </Menu.Item>
                      <Menu.Item onClick={() => deleteLine(d)}>
                        {translate(lang, 'Delete')}
                      </Menu.Item>
                    </Menu>
                  }
                  placement='bottomRight'
                  trigger={['click']}
                >
                  <div>
                    <DotsIcon />
                  </div>
                </Dropdown>
              </div>
            </>
          )}
        </div>
      ),
    },
  ]
    .filter(x => !x.title || !hiddenColumns?.includes(x.title))
    .map(x => ({
      ...x,
      title: translate(lang, x.title),
    }));

  return (
    <>
      <Table
        rowKey={'id'}
        className={`table table--isFarm ${isChild ? 'table--is__child' : ''}`}
        pagination={false}
        columns={columns}
        dataSource={linesData}
        onRow={dataRow => ({
          onClick: () => redirectToLine(dataRow.farm_id, dataRow.id),
        })}
        onChange={changeTable}
      />
      {editLine && (
        <LineFormModal
          visible={true}
          lineData={editLine}
          onClose={() => setEditLine(undefined)}
          title='Edit line details'
          type='OYSTER'
        />
      )}
    </>
  );
};

export default OysterLinesTable;
