import {
  IFarmResource,
  ILineResource,
  IMusselLineResource,
  IOysterLineResource,
  ISeaweedLineResource,
} from '../../../../entities/farms.entities';
import MusselLinesTable from './MusselLinesTable';
import OysterLinesTable from './OysterLinesTable';
import SeaweedLinesTable from './SeaweedLinesTable';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLang } from '../../../../store/ui/ui.selector';
import { translate } from '../../../../lib/lang.helper';
import Dropdown from '../../dropdown/Dropdown';
import Input from '../../input/Input';
import { Button } from 'antd';
import { SelectIsEditable } from '../../../../store/extra/extra.selector';
import LineColumnModifyModal from '../../../user-settings/LineColumnModifyModal';
import '../styles.scss';

const lineFilterOptions = (lang: any) => [
  { id: 'all', value: 'all', label: translate(lang, 'All') },
  {
    id: 'empty_lines',
    value: 'empty_lines',
    label: translate(lang, 'Empty Lines'),
  },
  {
    id: 'ready_harvest',
    value: 'ready_harvest',
    label: translate(lang, 'Ready To Harvest'),
  },
  {
    id: 'recently_seeded',
    value: 'recently_seeded',
    label: translate(lang, 'Recently Seeded'),
  },
];

interface Props {
  farmData: IFarmResource;
  isChild?: boolean;
}

const LinesTable = ({ farmData, isChild }: Props) => {
  const lang = useSelector(selectLang);
  const isEditable = SelectIsEditable(farmData.id);

  const [filterOpt, setFilterOpt] = useState('all');
  const [filterName, setFilterName] = useState('');
  const [visModify, setVisModify] = useState(false);

  const linesData = useMemo(() => {
    let result: ILineResource[] = [];
    if (filterOpt === 'empty_lines') {
      result = farmData.lines.filter(x => !x.growing_cycle);
    } else if (filterOpt === 'ready_harvest') {
      let res = farmData.lines.filter(
        x => x.growing_cycle && !!x.growing_cycle.ready_harvest,
      );
      if (res)
        res.sort((a, b) =>
          (a.growing_cycle?.ready_harvest ?? 0) >
          (b.growing_cycle?.ready_harvest ?? 0)
            ? -1
            : 1,
        );
      result = res;
    } else if (filterOpt === 'recently_seeded') {
      let res = farmData.lines.filter(x => !!x.growing_cycle);
      if (res)
        res.sort((a, b) =>
          (a.growing_cycle?.main_seed.planned_date_seed ?? 0) >
          (b.growing_cycle?.main_seed.planned_date_seed ?? 0)
            ? -1
            : 1,
        );
      result = res;
    } else {
      result = farmData.lines;
    }
    if (filterName) {
      result = result.filter(x =>
        x.line_name.toLowerCase().includes(filterName.toLowerCase()),
      );
    }
    return result;
  }, [farmData.lines, filterOpt, filterName]);

  return (
    <div className='w-100 overflow-auto'>
      {!isChild && (
        <div className='lines-filter-bar'>
          <div className='d-flex align-items-end'>
            <div style={{ minWidth: '200px' }}>
              <Dropdown
                label=''
                defaultValue={filterOpt}
                options={lineFilterOptions(lang)}
                onChange={val => setFilterOpt(val)}
              />
            </div>
            <div className='ml-12'>
              <Input
                type='text'
                label={translate(lang, 'Search by Line name')}
                value={filterName}
                placeholder=''
                onChange={e => setFilterName(e.target.value)}
              />
            </div>
          </div>
          {isEditable && (
            <Button type='primary' onClick={() => setVisModify(true)}>
              {translate(lang, 'Modify columns')}
            </Button>
          )}
        </div>
      )}
      {farmData.type === 'MUSSEL' ? (
        <MusselLinesTable
          farmId={farmData.id}
          linesData={linesData as IMusselLineResource[]}
          isChild={isChild}
        />
      ) : farmData.type === 'OYSTER' ? (
        <OysterLinesTable
          farmId={farmData.id}
          linesData={linesData as IOysterLineResource[]}
          isChild={isChild}
        />
      ) : (
        <SeaweedLinesTable
          farmId={farmData.id}
          linesData={linesData as ISeaweedLineResource[]}
          isChild={isChild}
        />
      )}
      {visModify && (
        <LineColumnModifyModal
          type={farmData.type}
          visible={true}
          onClose={() => setVisModify(false)}
        />
      )}
    </div>
  );
};

export default LinesTable;
