import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Caret,
  DotsIcon,
  Pen,
  PositiveNegativeTitle,
  Subtitle,
} from '../../components/shared';
import { Dropdown, Menu, Spin } from 'antd';
import { IMusselLineDetail } from '../../entities/farms.entities';
import { IMusselCycle } from '../../entities/growing.entities';
import { amountDays } from '../../lib/farm.helpers';
import {
  LineInventoriesLabel,
  SeedInventoriesLabel,
} from '../../components/CommonViews';
import { sendSingleRequest } from '../../apis';
import { loadFarmsData } from '../../store/farms/farms.actions';
import { showFeedback } from '../../store/ui/ui.actions';
import { useHistory } from 'react-router-dom';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import { averageValue, lineSizeLabel, rangeLabel } from '../../util/farmUtil';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import {
  SelectIsEditable,
  SelectIsFarmable,
  SelectIsGrower,
} from '../../store/extra/extra.selector';
import './styles.scss';

interface Props {
  lineData: IMusselLineDetail;
  cyclePart?: IMusselCycle | null;
  isPrevEnabled: boolean;
  isNextEnabled: boolean;
  onClickPrevGroup?: () => void;
  onClickNextGroup?: () => void;
  showEditLineModal: () => void;
  showEditSeedModal: () => void;
  onSeedingClick: () => void;
  onAssessmentClick: () => void;
  onCatchSpatClick: () => void;
  onHarvestClick: () => void;
  onMaintenanceClick: () => void;
  onFloatingManageClick: () => void;
  onExtraSeedClick: () => void;
  checkedHistoryView: number;
  onHistoryViewClick: () => void;
}

const MusselLineMobile = ({
  cyclePart,
  lineData,
  isPrevEnabled,
  isNextEnabled,
  onClickPrevGroup,
  onClickNextGroup,
  showEditLineModal,
  showEditSeedModal,
  onSeedingClick,
  onAssessmentClick,
  onCatchSpatClick,
  onHarvestClick,
  onMaintenanceClick,
  onFloatingManageClick,
  onExtraSeedClick,
  checkedHistoryView,
  onHistoryViewClick,
}: Props) => {
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const lang = useSelector(selectLang);
  const isGrower = SelectIsGrower(lineData.farm_id);
  const isEditable = SelectIsEditable(lineData.farm_id);
  const isFarmable = SelectIsFarmable(lineData.farm_id);
  const mainSeed = cyclePart?.main_seed;

  const menuClick = async (e: any) => {
    if (e.key === 'edit') {
      showEditLineModal();
    } else if (e.key === 'delete') {
      if (!window.confirm(translate(lang, 'Are you sure to delete this data?')))
        return;
      const res = await sendSingleRequest(
        {},
        'DELETE',
        `api/farm/line/lines/${lineData.id}`,
        true,
      );
      if (res.status) {
        await dispatch(loadFarmsData());
        history.replace(`/farms/${lineData.farm_id}`);
        dispatch(
          showFeedback({
            isMessage: true,
            type: 'success',
            message: translate(lang, 'Deleted successfully'),
          }),
        );
      } else {
        dispatch(
          showFeedback({
            isMessage: true,
            type: 'error',
            message: translate(lang, res.data?.message ?? 'Unknown error'),
          }),
        );
      }
    }
  };

  return (
    <>
      <div className='pt-16 pb-4'>
        <div className={'table-mobile__card'} onKeyDown={() => undefined}>
          <div className={'table-mobile__card-dots'}>
            {isEditable && (
              <div className='dropdown'>
                <Dropdown
                  overlay={
                    <Menu onClick={menuClick}>
                      <Menu.Item key='edit'>
                        {translate(lang, 'Edit')}
                      </Menu.Item>
                      <Menu.Item key='delete'>
                        {translate(lang, 'Delete')}
                      </Menu.Item>
                    </Menu>
                  }
                  placement='bottomRight'
                  trigger={['click']}
                >
                  <div>
                    <DotsIcon />
                  </div>
                </Dropdown>
              </div>
            )}
          </div>
          <div className='d-flex justify-content-between align-items-center'>
            <Subtitle size={5} color='black-3' align='left' fontWeight={600}>
              {translate(lang, 'Line - %s', lineData.line_name)}
            </Subtitle>
          </div>
          <div className='pt-16'>
            <div className='d-flex pb-23'>
              <div className='flex-basis-50'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Length')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  <>
                    <span className='pr-6'>
                      {lineData.growing_cycle
                        ? lineData.growing_cycle.total_seeded_length
                        : 0}
                    </span>
                    <span>m</span>
                  </>
                </Subtitle>
              </div>
              <div className='flex-basis-50 ml-24'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Date seeded')}
                </Subtitle>
                <>
                  {defaultDateFormat(
                    lineData.growing_cycle?.main_seed.planned_date_seed,
                    '-',
                  )}
                </>
              </div>
            </div>

            <div className='d-flex pb-23'>
              <div className='flex-basis-50'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Planned harvest date')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  <>
                    {defaultDateFormat(
                      lineData.growing_cycle?.last_assessment
                        ?.planned_date_harvest ??
                        lineData.growing_cycle?.main_seed.planned_date_harvest,
                      '-',
                    )}
                  </>
                </Subtitle>
              </div>
              <div className='flex-basis-50 ml-24'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Seed type')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  {lineData.growing_cycle?.main_seed.spat_storage?.seed_type ??
                    '-'}
                </Subtitle>
              </div>
            </div>

            <div className='d-flex pb-23'>
              <div className='flex-basis-50'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Income per meter')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  {lineData.last_harvest?.profit_per_meter ?? '-'}
                </Subtitle>
              </div>
              <div className='flex-basis-50 ml-24'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Avg. Size')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  <PositiveNegativeTitle
                    isColor={
                      lineData.growing_cycle?.last_assessment?.ready_harvest
                        ? 'good'
                        : 'normal'
                    }
                  >
                    {lineSizeLabel(lineData)}
                  </PositiveNegativeTitle>
                </Subtitle>
              </div>
            </div>
            <div className='d-flex pb-23'>
              <div className='flex-basis-50 '>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Density')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  {lineData.growing_cycle
                    ? rangeLabel(
                        lineData.growing_cycle.main_seed.density,
                        lineData.growing_cycle.main_seed.density_max,
                      )
                    : ''}
                </Subtitle>
              </div>
              <div className='flex-basis-50 ml-24'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Spat Size')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  {lineData.growing_cycle
                    ? averageValue(
                        lineData.growing_cycle.main_seed.spat_size,
                        lineData.growing_cycle.main_seed.spat_size_max,
                        'mm',
                      )
                    : ''}
                </Subtitle>
              </div>
            </div>
            <div className='d-flex pb-23'>
              <div className='flex-basis-50 '>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Submersion')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  {lineData.growing_cycle
                    ? `${lineData.growing_cycle.main_seed.submersion} m`
                    : '-'}
                </Subtitle>
              </div>
              <div className='flex-basis-50 ml-24'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Spacing')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  {lineData.growing_cycle
                    ? `${lineData.growing_cycle.main_seed.spacing} mm`
                    : '-'}
                </Subtitle>
              </div>
            </div>
            <div className='d-flex pb-23'>
              <div className='flex-basis-50 '>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Density')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  {lineData.growing_cycle
                    ? rangeLabel(
                        lineData.growing_cycle.main_seed.density,
                        lineData.growing_cycle.main_seed.density_max,
                      )
                    : ''}
                </Subtitle>
              </div>
              <div className='flex-basis-50 ml-24'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Floats')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  {lineData.growing_cycle?.main_seed.floats ?? '-'}
                </Subtitle>
              </div>
            </div>
            <div className='d-flex'>
              <div className='flex-basis-50 '>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Backbone')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  {lineData.backbone ? `${lineData.backbone}` : '-'}
                </Subtitle>
              </div>
              <div className='flex-basis-50 ml-24'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Inventories')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  {lineData.growing_cycle
                    ? SeedInventoriesLabel(
                        lineData.growing_cycle.inventories_sum,
                      )
                    : LineInventoriesLabel(lineData.pending_inventories ?? [])}
                </Subtitle>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='white-card pt-16 pl-12 pb-16 pr-12 mb-8'>
        <div className='d-flex justify-content-between'>
          <div className='w-100 d-flex justify-content-between align-items-center  white-card-small pt-3 pb-3'>
            <Button
              color='blue'
              size={3}
              width='default'
              type='transparent'
              className='mr-26'
              name='prev'
              onClick={onClickPrevGroup}
              disabled={!isPrevEnabled}
              onlyIconDisabled
            >
              <Caret color='#5A607F' direction='left' />
            </Button>
            <Subtitle
              size={4}
              color='black'
              align='left'
              fontWeight={500}
              disabled={!mainSeed}
            >
              {mainSeed?.season_name ?? translate(lang, 'Empty')}
            </Subtitle>
            <Button
              color='blue'
              size={3}
              width='default'
              type='transparent'
              className='ml-26'
              name='next'
              onClick={onClickNextGroup}
              disabled={!isNextEnabled}
              onlyIconDisabled
            >
              <Caret color='#5A607F' direction='right' />
            </Button>
          </div>
          <Button
            color='blue'
            size={0}
            width='default'
            type='bordered'
            className='ml-12'
            iconOnly
            onClick={showEditSeedModal}
            disabled={!isEditable || !mainSeed?.is_growing}
          >
            <Pen />
          </Button>
        </div>
        <div className='pt-16'>
          <>
            {mainSeed ? (
              <div className='d-flex flex-direction-col'>
                <button
                  className={`timeline-button${
                    checkedHistoryView === 1 ? ' --checked' : ''
                  }`}
                  onClick={onHistoryViewClick}
                >
                  {checkedHistoryView === -1 ? (
                    <div className='ml-17 mr-17'>
                      <Spin />
                    </div>
                  ) : (
                    translate(
                      lang,
                      checkedHistoryView === 1
                        ? 'Hide history'
                        : 'Show history',
                    )
                  )}
                </button>
                {mainSeed.is_growing ? (
                  <>
                    <Button
                      className='mb-8 mt-8'
                      color='blue'
                      size={1}
                      width='wide'
                      type='fill'
                      onClick={onFloatingManageClick}
                      disabled={!isFarmable}
                    >
                      {translate(lang, 'Inventory Management')}
                    </Button>
                    <Button
                      color='blue'
                      size={1}
                      width='wide'
                      type='fill'
                      onClick={onAssessmentClick}
                      disabled={!isFarmable}
                    >
                      {translate(lang, 'Add assessment')}
                    </Button>
                    {!mainSeed.is_catch_spat && (
                      <Button
                        className='mt-8'
                        color='blue'
                        size={1}
                        width='wide'
                        type='fill'
                        onClick={onExtraSeedClick}
                        disabled={!isFarmable}
                      >
                        {translate(lang, 'Add extra seed')}
                      </Button>
                    )}
                    <Button
                      className='mt-8'
                      color='green'
                      size={1}
                      width='wide'
                      type='fill'
                      onClick={onMaintenanceClick}
                      disabled={!isFarmable}
                    >
                      {translate(lang, 'Add maintenance')}
                    </Button>
                    <Button
                      color='blue'
                      size={1}
                      width='wide'
                      type='bordered'
                      className='mt-8'
                      onClick={onHarvestClick}
                      disabled={!isFarmable}
                    >
                      {translate(lang, 'Harvest Complete')}
                    </Button>
                  </>
                ) : (
                  <span></span>
                )}
              </div>
            ) : (
              <>
                {!isGrower && (
                  <Button
                    color='blue'
                    size={1}
                    width='wide'
                    type='fill'
                    onClick={onCatchSpatClick}
                    disabled={!isFarmable}
                  >
                    {translate(lang, 'Catch Spat')}
                  </Button>
                )}
                <Button
                  className='mt-10'
                  color='blue'
                  size={1}
                  width='wide'
                  type='fill'
                  onClick={onSeedingClick}
                  disabled={!isFarmable}
                >
                  {translate(lang, 'Seed the line')}
                </Button>
                <div className='d-flex justify-content-center align-items-center mt-10'>
                  <Subtitle
                    size={5}
                    color='black'
                    align='left'
                    fontWeight={400}
                  >
                    {translate(lang, 'Line empty for')}
                  </Subtitle>
                  <Subtitle
                    size={5}
                    color='black'
                    align='left'
                    fontWeight={600}
                    className='ml-4 mr-27'
                  >
                    {amountDays(lineData?.line_idle)}
                  </Subtitle>
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default MusselLineMobile;
