import moment from 'moment';
import { sendSingleRequest } from '../apis';
import { ILineResource } from '../entities/farms.entities';
import { compareString } from './common.helpers';
import { IAccountSetting } from '../entities/user.entities';
import { IUtilData } from '../entities/utils.entities';

export const updateLineColumn = async (line_id: number, col_no: number) => {
  const response = await sendSingleRequest(
    { line_id, col_no },
    'POST',
    `api/farm/line/visual-line/column`,
    true,
  );
  if (response.status) {
    return response.data;
  } else {
    throw new Error(response.data?.message ?? 'Something went wrong');
  }
};

export const updateLinePriority = async (line_id: number, priority: number) => {
  const response = await sendSingleRequest(
    { line_id, priority },
    'POST',
    `api/farm/line/visual-line/priority`,
    true,
  );
  if (response.status) {
    return response.data;
  } else {
    throw new Error(response.data?.message ?? 'Something went wrong');
  }
};

type LineStatus =
  | 'EMPTY'
  | 'READY_HARVEST'
  | 'REQUIRE_ASSESSMENT'
  | 'CATCH_SPAT'
  | 'NORMAL';

export const getLineStatus = (
  line: ILineResource,
  expire_days = 90,
): LineStatus => {
  const ready_harvest = line.growing_cycle?.ready_harvest;
  const requireAssessment = () => {
    if (!line || !line.growing_cycle) return false;
    const lastDate =
      line.growing_cycle.last_assessment?.assessment_date ??
      line.growing_cycle.main_seed.planned_date_seed;
    const d1 = moment(Number(lastDate) * 1000)
      .toDate()
      .getTime();
    const d2 = moment().toDate().getTime();
    const d = Math.abs(Math.round((d2 - d1) / 86400000));

    return d > (expire_days ?? 90);
  };
  const catchSpat = !!line.growing_cycle?.main_seed.is_catch_spat;

  if (!line.growing_cycle) return 'EMPTY';
  else if (ready_harvest) return 'READY_HARVEST';
  else if (requireAssessment()) return 'REQUIRE_ASSESSMENT';
  else if (catchSpat) return 'CATCH_SPAT';
  else return 'NORMAL';
};

export const setFarmVisualFloatsPosition = async (
  seeding_id: number,
  inventory_id: number,
  positions: number[],
  shouldQueue: boolean = false,
) => {
  setLocalFloatsPosition(seeding_id, inventory_id, positions);

  if (shouldQueue) {
    if (!!(window as any).VisualFloatsPositionTimer) {
      clearTimeout((window as any).VisualFloatsPositionTimer);
    }
    if (!(window as any).VisualFloatsPositionQueue) {
      (window as any).VisualFloatsPositionQueue = [];
    }
    (window as any).VisualFloatsPositionQueue.push({
      seeding_id,
      inventory_id,
      positions,
    });
    (window as any).VisualFloatsPositionTimer = setTimeout(async () => {
      if (
        !!(window as any).VisualFloatsPositionQueue &&
        (window as any).VisualFloatsPositionQueue.length > 0
      ) {
        await sendSingleRequest(
          { bulk_data: (window as any).VisualFloatsPositionQueue },
          'POST',
          `api/farm/line/visual-floats-position`,
          true,
        );
        (window as any).VisualFloatsPositionQueue = null;
      }
    }, 3000);
  } else {
    const response = await sendSingleRequest(
      { seeding_id, inventory_id, positions },
      'POST',
      `api/farm/line/visual-floats-position`,
      true,
    );
    return response.status;
  }
};

export const getLocalFloatsPosition = (
  seeding_id: number,
  inventory_id: number,
) => {
  const res = localStorage.getItem(
    `farm_visual_floats_position_${seeding_id}_${inventory_id}`,
  );
  if (res) return JSON.parse(res);
  return null;
};

export const setLocalFloatsPosition = (
  seeding_id: number,
  inventory_id: number,
  positions: number[],
) => {
  localStorage.setItem(
    `farm_visual_floats_position_${seeding_id}_${inventory_id}`,
    JSON.stringify(positions),
  );
};

export const tableLineSorting = (
  array: ILineResource[],
  order: string,
  columnName: any,
) => {
  const newArray = array;
  if (order === 'ascend') {
    switch (columnName) {
      case 'line_name':
        newArray.sort((a, b) => {
          return compareString(a.line_name, b.line_name);
        });
        break;
      case 'length':
        newArray.sort((a, b) => (a as any).length - (b as any).length);
        break;
      case 'seeded_date':
        newArray.sort((a, b) => {
          if (a.growing_cycle) {
            return (
              Number(a.growing_cycle.main_seed.planned_date_seed) -
              Number(b.growing_cycle?.main_seed.planned_date_seed)
            );
          } else {
            return (a.line_idle ?? 0) - (b.line_idle ?? 0);
          }
        });
        break;
      case 'planned_date':
        newArray.sort((a, b) => {
          if (a.growing_cycle) {
            return Number(a.line_idle) - Number(b.line_idle);
          } else {
            return (a.line_idle ?? 0) - (b.line_idle ?? 0);
          }
        });
        break;
      case 'seed':
        newArray.sort((a, b) => {
          if (a.growing_cycle) {
            return (
              Number(a.growing_cycle.main_seed.planned_date_seed) -
              Number(b.growing_cycle?.main_seed.planned_date_seed)
            );
          } else {
            return (a.line_idle ?? 0) - (b.line_idle ?? 0);
          }
        });
        break;
    }
  } else if (order === 'descend') {
    switch (columnName) {
      case 'line_name':
        newArray.sort((a, b) => {
          return compareString(b.line_name, a.line_name);
        });
        break;
      case 'length':
        newArray.sort((a, b) => (a as any).length - (b as any).length);
        break;
      case 'seeded_date':
        newArray.sort((a, b) => {
          if (b.growing_cycle) {
            return (
              Number(b.growing_cycle.main_seed.planned_date_seed) -
              Number(a.growing_cycle?.main_seed.planned_date_seed)
            );
          } else {
            return (b.line_idle ?? 0) - (a.line_idle ?? 0);
          }
        });
        break;
      case 'planned_date':
        newArray.sort((a, b) => {
          if (b.growing_cycle) {
            return Number(b.line_idle) - Number(a.line_idle);
          } else {
            return (b.line_idle ?? 0) - (a.line_idle ?? 0);
          }
        });
        break;
      case 'seed':
        newArray.sort((a, b) => {
          if (b.growing_cycle) {
            return (
              Number(b.growing_cycle.main_seed.planned_date_seed) -
              Number(a.growing_cycle?.main_seed.planned_date_seed)
            );
          } else {
            return (b.line_idle ?? 0) - (a.line_idle ?? 0);
          }
        });
        break;
    }
  }
  return newArray;
};

export const amountDays = (time: number | undefined | null): string => {
  if (time === null) {
    return '0 days';
  }

  if (Number(time) === 1) {
    return '1 day';
  }

  return `${time} days`;
};

export const calcOnGrowWastePercent = (
  onGrowWaste: IAccountSetting['oyster_crops'],
  type: 'grow' | 'waste',
  lineID: number | undefined = undefined,
) => {
  if (!lineID) {
    return (
      (type === 'grow' ? onGrowWaste?.grow_ons : onGrowWaste?.waste) ??
      undefined
    );
  } else {
    return (
      (type === 'grow'
        ? onGrowWaste?.lines_grow_ons &&
          onGrowWaste.lines_grow_ons[lineID] !== undefined &&
          onGrowWaste.lines_grow_ons[lineID] !== null
          ? onGrowWaste.lines_grow_ons[lineID]
          : onGrowWaste?.grow_ons
        : onGrowWaste?.lines_waste &&
          onGrowWaste.lines_waste[lineID] !== undefined &&
          onGrowWaste.lines_waste[lineID] !== null
        ? onGrowWaste.lines_waste[lineID]
        : onGrowWaste?.waste) ?? undefined
    );
  }
};

export const calcOysterPercent = (
  util: IUtilData,
  lineID: number | undefined,
) => {
  if (!lineID) {
    return util.num_val ?? undefined;
  } else {
    return util.json &&
      util.json.lines &&
      util.json.lines[lineID] !== undefined &&
      util.json.lines[lineID] !== null
      ? (util.json.lines[lineID] as number)
      : util.num_val ?? undefined;
  }
};
