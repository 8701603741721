import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { sendSingleRequest } from '../../apis';
import {
  Button,
  Datepicker,
  Dropdown,
  Spinner,
  Title,
} from '../../components/shared';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import { Table } from 'antd';
import useMenuHandler from '../../components/shared/tables/useMenuHandler';
import { formatNumber, numberToMoneyStr } from '../../entities/util-functions';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { selectFarmsData } from '../../store/farms/farms.selector';
import {
  IHarvestResource,
  IMusselHarvest,
  IOysterHarvest,
} from '../../entities/growing.entities';
import HarvestPDFCodeButton from './HarvestPDFCodeButton';
import { IFarmResource } from '../../entities/farms.entities';
import { selectAccount } from '../../store/auth/auth.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import './styles.scss';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Chart.js Bar Chart',
    },
  },
};

const convertRow = (row: IHarvestResource, farms: IFarmResource[]) => {
  const farm = farms.find(x => x.id === row.line.farm_id) as IFarmResource;
  const amount = (row as any).is_waste
    ? 'Stripped to waste'
    : farm.type === 'MUSSEL'
    ? `${formatNumber((row as IMusselHarvest).amount)} kg`
    : `${formatNumber((row as IOysterHarvest).amount_dz)} dozens`;
  const quantity = (row as any).is_waste
    ? ''
    : farm.type === 'MUSSEL'
    ? `${(row as IMusselHarvest).bags_quantity ?? 0} bags`
    : `${row.line_length ?? 0} ${(row.line as any).farming_method}`;
  const income = (row as any).is_waste
    ? ''
    : `$${numberToMoneyStr(row.income ?? 0)}`;
  return {
    id: row.id,
    season: row.season_name,
    date: row.complete_date,
    farm: farm.name,
    line: row.line.line_name,
    amount,
    income,
    is_full: row.is_full,
    quantity,
  };
};

const columns: any = [
  {
    key: 'id',
    title: 'ID',
    dataIndex: 'id',
    render: (v: number) => <span>{v}</span>,
  },
  {
    key: 'season',
    title: 'Season',
    dataIndex: 'season',
    render: (x: string) => <span>{x}</span>,
  },
  {
    key: 'date',
    title: 'Date',
    dataIndex: 'date',
    render: (x: number) => <span>{defaultDateFormat(x)}</span>,
  },
  {
    key: 'farm',
    title: 'Farm',
    dataIndex: 'farm',
    render: (x: string) => <span>{x}</span>,
  },
  {
    key: 'line',
    title: 'Line',
    dataIndex: 'line',
    render: (x: string) => <span>{x}</span>,
  },
  {
    key: 'tones',
    title: 'Amount',
    dataIndex: 'amount',
    render: (x: string) => <span>{x}</span>,
  },
  {
    key: 'income',
    title: 'Income',
    dataIndex: 'income',
    render: (x: string) => <span>{x}</span>,
  },
  {
    key: 'is_full',
    title: 'F/P',
    dataIndex: 'is_full',
    render: (x: boolean) => <span>{!x ? 'Partial' : 'Full'}</span>,
  },
  {
    key: 'quantity',
    title: 'Volume Quantity',
    dataIndex: 'quantity',
    render: (x: string) => <span>{x}</span>,
  },
  {
    key: 'empty',
    title: '',
    dataIndex: '',
    render: (x: any) => <span></span>,
  },
];

const colors = [
  '#0d88e6',
  '#fd7f6f',
  '#7eb0d5',
  '#b2e061',
  '#bd7ebe',
  '#ffb55a',
  '#ffee65',
  '#beb9db',
  '#fdcce5',
  '#8bd3c7',
  '#4421af',
  '#1a53ff',
];

interface IFltOption {
  season: string;
  date_from: number;
  date_to: number;
  farm_id?: number;
  line_id?: number;
}

const SummaryHarvestPage = () => {
  const account = useSelector(selectAccount);
  const farmsData = useSelector(selectFarmsData);
  const lang = useSelector(selectLang);

  const [data, setData] = useState<IHarvestResource[]>([]);
  const [bestPerformingData, setBestPerformingData] = useState<any>();
  const [catchSpatMonthData, setCatchSpatMonthData] = useState<any>();
  const [lineData, setLineData] = useState<any>();
  const [monthData, setMonthData] = useState<any>();
  const [pageOptions, setPageOptions] = useState({
    current: 1,
    pageSize: 10,
    total: data.length ?? 0,
  });
  const { redirectToHarvestDetail } = useMenuHandler();
  const [fltOpt, setFltOpt] = useState<IFltOption>({
    season: 'All',
    date_from: moment().toDate().getTime(),
    date_to: moment().toDate().getTime(),
  });
  const [loading, setLoading] = useState(31);

  const loadData = useCallback(() => {
    setLoading(0);
    sendSingleRequest({}, 'GET', 'api/overview/harvests', true).then(res => {
      setLoading(p => p | 1);
      if (res.status) {
        const tmpHv: IHarvestResource[] = res.data;
        setData(tmpHv);
        setPageOptions({ ...pageOptions, total: tmpHv.length });

        const dateMin = tmpHv.reduce(
          (p, c) => (p > c.complete_date ? c.complete_date : p),
          Date.now(),
        );
        const dateMax = tmpHv.reduce(
          (p, c) => (p < c.complete_date ? c.complete_date : p),
          0,
        );
        setFltOpt(prv => ({
          ...prv,
          date_from: 1000 * dateMin,
          date_to: 1000 * dateMax,
        }));
      }
    });
    sendSingleRequest(
      { type: 'normal' },
      'GET',
      'api/overview/harvest-summary',
      true,
    ).then(res => {
      setLoading(p => p | 2);
      if (res.status) setMonthData(res.data);
    });
    sendSingleRequest(
      { type: 'catch_spat' },
      'GET',
      'api/overview/harvest-summary',
      true,
    ).then(res => {
      setLoading(p => p | 4);
      if (res.status) setCatchSpatMonthData(res.data);
    });
    sendSingleRequest(
      { type: 'line' },
      'GET',
      'api/overview/harvest-summary',
      true,
    ).then(res => {
      setLoading(p => p | 8);
      if (res.status) setLineData(res.data);
    });
    sendSingleRequest({}, 'GET', 'api/overview/best-perform-line', true).then(
      res => {
        setLoading(p => p | 16);
        if (res.status) setBestPerformingData(res.data);
      },
    );
  }, []);

  const handlePageChange = (pageOptions: any) => {
    setPageOptions(pageOptions);
  };
  const getHarvestPerYearDataSets = (data: any) => {
    const labels: any = [];
    const datasets: any = [];
    let i = 0;
    for (let y in data) {
      const d: any = {
        label: y,
        data: [],
        backgroundColor: colors[i],
      };
      i = (i + 1) % 12;
      for (let m in data[y]) {
        if (!labels.includes(m)) labels.push(m);
        d.data.push(data[y][m]);
      }
      datasets.push(d);
    }
    return {
      labels,
      datasets,
    };
  };
  const getBestPerformingDataSets = (data: any) => {
    const labels: any = [];
    const datasets: any = [];
    let i = 0;
    for (let f in data) {
      const d: any = {
        label: f,
        data: [],
        backgroundColor: colors[i],
      };
      i = (i + 1) % 12;
      for (let m in data[f]) {
        if (!labels.includes(m)) labels.push(m);
        d.data.push(data[f][m]);
      }
      datasets.push(d);
    }
    return {
      labels,
      datasets,
    };
  };

  const harvests = useMemo(() => {
    let result = data.filter(
      x => fltOpt.season === 'All' || x.season_name === fltOpt.season,
    );
    result = result.filter(
      x =>
        1000 * x.complete_date >= fltOpt.date_from &&
        1000 * x.complete_date <= fltOpt.date_to,
    );
    if (fltOpt.farm_id) {
      result = result.filter(x => x.line.farm_id === fltOpt.farm_id);
    }
    if (fltOpt.line_id) {
      result = result.filter(x => x.line_id === fltOpt.line_id);
    }
    result.sort((a, b) => b.complete_date - a.complete_date);
    return result.map(x => convertRow(x, farmsData));
  }, [data, fltOpt, farmsData]);

  const seasons = useMemo(() => {
    let res = [{ id: 'All', label: 'All', value: 'All' }];
    for (let x of data) {
      if (!res.some(t => t.id === x.season_name))
        res.push({
          id: x.season_name,
          label: x.season_name,
          value: x.season_name,
        });
    }
    return res;
  }, [data]);

  const lastHarvest = useMemo(() => {
    let ls = data.filter(x => !!x.harvest_number);
    if (ls.length <= 0) return null;

    ls.sort((a, b) => b.complete_date - a.complete_date);
    return ls[0];
  }, [data]);

  const farmsOption = [
    { id: 'All', value: 'ALL', label: 'All' },
    ...farmsData.map(x => ({
      id: x.id.toString(),
      value: x.id.toString(),
      label: x.name,
    })),
  ];
  const linesOption = fltOpt.farm_id
    ? [
        { id: 'All', value: 'ALL', label: 'All' },
        ...(farmsData
          .find(x => x.id === fltOpt.farm_id)
          ?.lines.map(x => ({
            id: x.id.toString(),
            value: x.id.toString(),
            label: x.line_name,
          })) ?? []),
      ]
    : null;

  const filterFarmChange = (v: string) => {
    const f = v === 'ALL' ? undefined : farmsData.find(x => x.id === Number(v));
    setFltOpt(prv => ({ ...prv, farm_id: f?.id, line_id: undefined }));
  };
  const filterLineChange = (v: string) => {
    if (v === 'ALL') {
      setFltOpt(prv => ({ ...prv, line_id: undefined }));
    } else {
      const f = farmsData.find(x => x.lines.some(y => y.id === Number(v)));
      const l = f?.lines.find(x => x.id === Number(v));
      setFltOpt(prv => ({ ...prv, line_id: l?.id }));
    }
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    setPageOptions({ ...pageOptions, total: harvests.length });
  }, [harvests]);

  return (
    <div className='bg-secondary min-h-100'>
      <div className='container'>
        <div className='overview d-flex justify-content-between align-items-center'>
          <Title size={5} color='black' align='default' fontWeight={700}>
            {translate(lang, 'Harvest Stats')}
          </Title>
          <div className='d-flex'>
            <HarvestPDFCodeButton lastHarvest={lastHarvest} />
            <Link to='/summary/processing' className='ml-12'>
              <Button color='blue' size={3} width='middle' type='fill'>
                {translate(lang, 'View Processing Stats')}
              </Button>
            </Link>
          </div>
        </div>
        <div className='chart--content'>
          <div className='row'>
            <div className='chart--card'>
              {!(loading & 2) ? (
                <div className='mt-32 mb-32'>
                  <Spinner />
                </div>
              ) : (
                <div className='chart-card'>
                  <div className='chart--title'>
                    {translate(lang, 'Harvest per year')}
                  </div>
                  <Bar
                    options={options}
                    data={getHarvestPerYearDataSets(monthData)}
                  />
                </div>
              )}
            </div>
            <div className='chart--card'>
              {!(loading & 4) ? (
                <div className='mt-32 mb-32'>
                  <Spinner />
                </div>
              ) : (
                <div className='chart-card'>
                  <div className='chart--title'>
                    {translate(lang, 'Harvests of spat catch per year')}
                  </div>
                  <Bar
                    options={options}
                    data={getHarvestPerYearDataSets(catchSpatMonthData)}
                  />
                </div>
              )}
            </div>
          </div>
          <div className='row'>
            <div className='chart--card'>
              {!(loading & 8) ? (
                <div className='mt-32 mb-32'>
                  <Spinner />
                </div>
              ) : (
                <div className='chart-card'>
                  <div className='chart--title'>
                    {translate(lang, 'Best harvested lines')}
                  </div>
                  <Bar
                    options={options}
                    data={getBestPerformingDataSets(lineData)}
                  />
                </div>
              )}
            </div>
            <div className='chart--card'>
              {!(loading & 16) ? (
                <div className='mt-32 mb-32'>
                  <Spinner />
                </div>
              ) : (
                <div className='chart-card'>
                  <div className='chart--title'>
                    {translate(lang, 'Best performing lines by harvest tones')}
                  </div>
                  <Bar
                    options={options}
                    data={getBestPerformingDataSets(bestPerformingData)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='farms__line-content'>
          <div className='d-flex justify-content-end align-items-end mt-17 mb-8'>
            <div className='mr-7' style={{ width: '220px' }}>
              <Dropdown
                label={translate(lang, 'Filter by farm')}
                placeholder={translate(lang, 'Select farm')}
                options={farmsOption}
                value={
                  farmsOption.find(x => Number(x.id) === fltOpt.farm_id)?.id ??
                  'ALL'
                }
                onChange={filterFarmChange}
              />
            </div>
            <div className='mr-17 ml-7' style={{ width: '150px' }}>
              <Dropdown
                label={translate(lang, 'Filter by line')}
                placeholder={translate(lang, 'Select line')}
                options={linesOption ?? []}
                value={
                  !linesOption
                    ? undefined
                    : linesOption?.find(x => Number(x.id) === fltOpt.line_id)
                        ?.id ?? 'ALL'
                }
                onChange={filterLineChange}
                disabled={!linesOption}
              />
            </div>
            <div className='ml-7 mr-7'>
              <Datepicker
                label={translate(lang, 'Date from')}
                defaultValue={fltOpt.date_from}
                onChange={e =>
                  e &&
                  setFltOpt(prv => ({
                    ...prv,
                    date_from: e.toDate().getTime(),
                  }))
                }
              />
            </div>
            <div className='ml-7 mr-7'>
              <Datepicker
                label={translate(lang, 'Date to')}
                defaultValue={fltOpt.date_to}
                onChange={e =>
                  e &&
                  setFltOpt(prv => ({ ...prv, date_to: e.toDate().getTime() }))
                }
              />
            </div>
            <div className='ml-7'>
              <Dropdown
                className='filter-option'
                label={translate(lang, 'Filter by season')}
                value={fltOpt.season}
                options={seasons}
                onChange={v => setFltOpt(prv => ({ ...prv, season: v }))}
              />
            </div>
          </div>
          <div className='d-flex farms__main'>
            <div className='w-100 pos-relative overflow-auto'>
              <Table
                rowKey='id'
                className='table table--isFarm'
                pagination={pageOptions}
                onChange={handlePageChange}
                columns={
                  !account?.show_budget_menu
                    ? columns
                        .filter((x: any) => x.key !== 'income')
                        .map((x: any) => ({
                          ...x,
                          title: translate(lang, x.title),
                        }))
                    : columns.map((x: any) => ({
                        ...x,
                        title: translate(lang, x.title),
                      }))
                }
                dataSource={harvests}
                onRow={h => ({
                  onClick: () => redirectToHarvestDetail(h.id),
                })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryHarvestPage;
