import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import moment from 'moment';
import {
  Button,
  CheckboxButton,
  CloseIcon,
  Datepicker,
  Dropdown,
  Input,
  Subtitle,
} from '../../shared';
import { showFeedback } from '../../../store/ui/ui.actions';
import { sendSingleRequest } from '../../../apis';
import {
  formatNumber,
  numberToMoneyStr,
} from '../../../entities/util-functions';
import { IXeroInvoice } from '../../../entities/subscription.entities';
import GrowerInvoiceCreateView from '../../budget/GrowerInvoiceCreateView';
import {
  loadFarmsData,
  loadSpatStorages,
} from '../../../store/farms/farms.actions';
import ModalFeedbackView from '../../shared/feedback/ModalFeedbackView';
import {
  defaultDateFormat,
  toMillisecond,
  toSecond,
} from '../../../util/toggleSecondMillisecond';
import PDFWall from '../../shared/pictures-wall/PDFWall';
import { parseImageUrlToFileItem } from '../../../util/getBase64';
import { IMusselHarvest } from '../../../entities/growing.entities';
import {
  IMusselLineDetail,
  IMusselLineResource,
} from '../../../entities/farms.entities';
import ExpensesForm from '../../grower-views/ExpensesForm';
import { IExpenseRequest } from '../../../entities/budget.entities';
import { selectLang } from '../../../store/ui/ui.selector';
import { translate } from '../../../lib/lang.helper';
import { SelectMusselFarmsByFarmID } from '../../../store/extra/extra.selector';

interface ISRange {
  id: number;
  season_name: string;
  from_date: number;
  to_date: number;
  seed_count: number;
  harvest_count: number;
}

interface IFormData {
  seeding_id?: number;
  rope_bags_quantity: number | null;
  harvest_number: string | null;
  complete_date: number;
  port_of_unload: string | null;
  vessel: string | null;
  company: string | null;
  comment: string | null;
}

const defaultForm: IFormData = {
  complete_date: moment().toDate().getTime(),
  company: null,
  vessel: null,
  harvest_number: null,
  port_of_unload: null,
  rope_bags_quantity: null,
  comment: null,
};

const extractData = (data: any) => {
  if (!data) return { ...defaultForm };
  let res: any = { ...defaultForm };
  for (let k in defaultForm) {
    if (!data[k]) continue;
    res[k] = k === 'complete_date' ? 1000 * data[k] : data[k];
  }
  return res;
};

interface IHarvestLine {
  line_id: number;
  bags_quantity: number | null;
  is_full: boolean;
  line_length: string | null;
}

interface Props {
  visible: boolean;
  lineData?: IMusselLineResource;
  onClose: (d: any) => void;
  onConfirm: (d: any) => void;
  harvestData?: IMusselHarvest;
  updateId?: number;
  paramData?: any;
  overConfirm?: (d: any) => void;
  confirmID?: number;
  className?: string;
  warningMessage?: string;
}

const MusselGrowerHarvestModal = ({
  visible,
  lineData,
  onConfirm,
  onClose,
  harvestData,
  updateId,
  overConfirm,
  paramData,
  confirmID,
  className,
  warningMessage,
}: Props) => {
  const dispatch = useDispatch<any>();

  const lang = useSelector(selectLang);
  const farms = SelectMusselFarmsByFarmID(lineData?.farm_id);
  const allLines =
    farms
      .reduce((p, c) => [...p, ...c.lines], [] as IMusselLineResource[])
      .map(x => ({
        id: x.id.toString(),
        value: x.id.toString(),
        label: `${x.farm_name} - ${x.line_name}`,
        is_growing: !!x.growing_cycle,
        last_harvest: x.last_harvest,
      })) ?? [];
  const linesOption = updateId ? allLines : allLines.filter(x => x.is_growing);

  const [disabled, setDisabled] = useState(false);
  const [harvestedLines, setHarvestedLines] = useState<IHarvestLine[]>(
    lineData
      ? [
          {
            line_id: lineData.id,
            bags_quantity: harvestData?.bags_quantity ?? null,
            is_full: harvestData?.is_full ?? true,
            line_length: harvestData?.line_length?.toString() ?? null,
          },
        ]
      : [],
  );
  const [showConfirm, setShowConfirm] = useState(false);
  const [submittedData, setSubmittedData] = useState<any>();
  const [harvestCosts, setHarvestCosts] = useState<IExpenseRequest[]>(
    harvestData?.expense_items?.map(x => ({
      name: x.name,
      amount: x.amount,
      quantity: x.quantity,
      unit_price: x.quantity
        ? Math.round((x.amount / x.quantity) * 100) / 100
        : null,
    })) ?? [{ name: '', amount: null, quantity: null }],
  );
  const [formData, setFormData] = useState<IFormData>(extractData(harvestData));
  const [totAmount, setTotAmount] = useState(
    harvestData?.amount.toString() ?? '',
  );
  const [totIncome, setTotIncome] = useState(
    harvestData?.income?.toString() ?? '',
  );
  const [forInsert, setForInsert] = useState(false);
  const [ranges, setRanges] = useState<ISRange[]>();
  const [pdfFiles, setPdfFiles] = useState<any[]>(
    harvestData?.pdf_files?.map(x => parseImageUrlToFileItem(x)) ?? [],
  );
  const [isMiddle, setIsMiddle] = useState(
    harvestData && !harvestData.is_final ? true : false,
  );

  const showError = (message: string) =>
    dispatch(showFeedback({ isMessageModal: true, type: 'error', message }));
  const updateHarvestLine = (
    id: number,
    key: 'bags_quantity' | 'is_full' | 'line_length' | 'amount' | 'income',
    val: any,
  ) => {
    let tmp = [...harvestedLines];
    const i = tmp.findIndex(x => x.line_id === id);
    if (key === 'bags_quantity') val = Number(val);
    if (i > -1) (tmp[i] as any)[key] = val;
    setHarvestedLines(tmp);
  };
  const updateForm = (key: keyof IFormData, value: any) => {
    setFormData(prv => ({ ...prv, [key]: value }));
  };
  const selectLine = (val: string[]) => {
    let tmp = val.map(x => ({
      line_id: Number(x),
      bags_quantity: null,
      is_full: true,
      line_length: null,
    }));
    for (let t of harvestedLines) {
      const i = tmp.findIndex(x => x.line_id === t.line_id);
      if (i > -1) {
        tmp[i].bags_quantity = t.bags_quantity as any;
        tmp[i].is_full = t.is_full;
        tmp[i].line_length = t.line_length as any;
      }
    }
    setHarvestedLines(tmp);
  };
  const validData = () => {
    if (harvestedLines.length <= 0) {
      showError(translate(lang, 'Please select harvested line'));
      return null;
    }
    if (
      harvestedLines.findIndex(
        x => !x.bags_quantity || Number(x.bags_quantity) <= 0,
      ) > -1
    ) {
      showError(translate(lang, 'Please input valid number of bags quantity'));
      return null;
    }
    if (Number(totAmount) <= 0) {
      showError(translate(lang, 'Please input valid harvest amount'));
      return null;
    }
    const totBags = harvestedLines.reduce(
      (p, c) => p + Number(c.bags_quantity),
      0,
    );
    if (updateId) {
      if (harvestedLines.length !== 1) {
        showError(
          translate(
            lang,
            'Unknown error! Please contact to the developer team',
          ),
        );
        return null;
      }
      const tmpL = allLines.find(x => Number(x.id) === lineData?.id);
      if (!tmpL) {
        showError(
          translate(
            lang,
            'Data is still loading. Please try after few seconds!',
          ),
        );
        return null;
      }
      if (
        harvestData?.is_full !== undefined &&
        harvestData.is_full !== harvestedLines[0].is_full &&
        tmpL.last_harvest?.id !== updateId
      ) {
        showError(translate(lang, '_impossible_harvest_update'));
        return null;
      }
      return {
        ...formData,
        is_final: !isMiddle,
        line_length: harvestedLines[0].line_length,
        is_full: harvestedLines[0].is_full,
        bags_quantity: harvestedLines[0].bags_quantity,
        amount: Number(totAmount),
        income: Number(totIncome),
        expenses: harvestCosts.filter(x => x.quantity && x.amount),
        complete_date: toSecond(formData.complete_date),
      };
    } else {
      return {
        ...formData,
        pdf_files: pdfFiles,
        lines: harvestedLines.map(x => ({
          ...x,
          is_final: !isMiddle,
          amount: (Number(totAmount) / totBags) * Number(x.bags_quantity),
          income: (Number(totIncome) / totBags) * Number(x.bags_quantity),
        })),
        expenses: harvestCosts.filter(x => x.quantity && x.amount),
        complete_date: toSecond(formData.complete_date),
      };
    }
  };
  const invoiceDetail = (): IXeroInvoice => {
    const totBags = harvestedLines.reduce(
      (p, c) => p + Number(c.bags_quantity),
      0,
    );
    let line_items: any = [];
    for (let hl of harvestedLines) {
      const line = allLines.find(t => Number(t.id) === hl.line_id);
      if (!line) continue;
      const rate = Number(hl.bags_quantity) / totBags;
      line_items.push({
        item_name: line.label,
        description: 'Income',
        quantity: 1,
        unit_price: Number(totIncome) * rate,
      });
      for (let item of harvestCosts) {
        const qty = (Number(item.quantity) ? Number(item.quantity) : 1) * rate;
        const amt = Number(item.amount) ? Number(item.amount) * rate : 0;
        line_items.push({
          item_name: line.label,
          description: item.name,
          quantity: qty,
          unit_price: -amt / qty,
        });
      }
    }
    return {
      reference: 'Harvest Invoice',
      contact_id: '',
      line_items,
      type: 'ACCREC',
    };
  };
  const toggleForInsert = async (c: boolean) => {
    if (c) {
      const response = await sendSingleRequest(
        {},
        'GET',
        `api/farm/line/lines/${harvestedLines[0].line_id}`,
        true,
      );
      if (response.status) {
        const lineSource: IMusselLineDetail = response.data;
        let dt: ISRange[] = [];
        for (let row of lineSource.cycles) {
          const lstH = row.harvests.find(x => x.is_full);
          dt.push({
            id: row.main_seed.id,
            season_name: row.main_seed.season_name,
            from_date: row.main_seed.planned_date_seed,
            to_date: lstH?.complete_date ?? row.main_seed.planned_date_harvest,
            seed_count: row.seedings.length,
            harvest_count: row.harvests.length,
          });
        }
        setRanges(dt);
      }
    } else {
      updateForm('seeding_id', undefined);
      setRanges(undefined);
    }
    setForInsert(c);
  };
  const confirmClick = async () => {
    const data = validData();
    if (!data) return;

    if (!showConfirm) {
      setShowConfirm(true);
      return;
    }
    setDisabled(true);
    if (overConfirm) {
      overConfirm(data);
      return;
    }
    const res = updateId
      ? await sendSingleRequest(
          data,
          'POST',
          `api/farm/line/harvest-update/${updateId}`,
          true,
        )
      : confirmID
      ? await sendSingleRequest(
          { ...data, id: confirmID },
          'POST',
          'api/queue/confirm-bulk-harvest/mussel',
          true,
        )
      : await sendSingleRequest(
          data,
          'POST',
          'api/farm/line/bulk-mussel-harvest',
          true,
        );
    if (res.status) {
      setDisabled(false);
      dispatch(
        showFeedback({
          isMessageModal: true,
          message: translate(
            lang,
            updateId
              ? 'Updated successfully'
              : res.data?.message ?? 'Harvested successfully',
          ),
          type: 'success',
        }),
      );
      setSubmittedData(res.data);
      dispatch(loadFarmsData());
      if (isMiddle) {
        dispatch(loadSpatStorages());
      }
    } else {
      setDisabled(false);
      showError(translate(lang, res.data?.message ?? 'Failed to harvest'));
    }
  };

  useEffect(() => {
    if (paramData) {
      const {
        lines,
        bags_quantity,
        is_full,
        is_final,
        line_length,
        pdf_files,
        expenses,
        complete_date,
        amount,
        income,
        ...params
      } = paramData;
      if (pdf_files) {
        setPdfFiles(pdf_files);
      }
      if (lines) {
        setIsMiddle(!lines[0].is_final);
        setHarvestedLines(
          lines.map((x: any) => ({
            line_id: x.line_id,
            bags_quantity: x.bags_quantity,
            is_full: x.is_full,
            line_length: x.line_length,
          })),
        );
        setTotAmount(lines.reduce((p: number, c: any) => p + c.amount, 0));
        setTotIncome(lines.reduce((p: number, c: any) => p + c.income, 0));
      } else if (lineData) {
        setIsMiddle(!is_final);
        setHarvestedLines([
          {
            line_id: lineData.id,
            bags_quantity: bags_quantity,
            is_full: is_full,
            line_length: line_length,
          },
        ]);
        if (amount) setTotAmount(amount);
        if (income) setTotIncome(income);
      }
      if (expenses) {
        setHarvestCosts(expenses);
      }
      setFormData({ ...params, complete_date: toMillisecond(complete_date) });
    }
  }, [paramData, lineData]);

  const costSum = harvestCosts.reduce((p, c) => p + Number(c.amount), 0);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      className={className}
      closable
      maskClosable={false}
      closeIcon={<CloseIcon />}
      width={1000}
      footer={null}
    >
      {!showConfirm ? (
        <div className='grower-harvest-modal wrap'>
          <div className='d-flex align-items-center mb-17'>
            <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
              {translate(lang, 'INVOICE DETAILS')}
            </Subtitle>
          </div>
          {!!warningMessage && (
            <div className='header-alert'>{warningMessage}</div>
          )}
          <div className='section'>
            <div className='mb-17'>
              <Dropdown
                label={translate(lang, 'Select harvested lines')}
                placeholder={translate(lang, 'Select harvested lines')}
                mode='multiple'
                options={linesOption}
                value={harvestedLines.map(x => x.line_id.toString()) as any}
                onChange={(v: any) => selectLine(v)}
                showSearch={true}
                disabled={!!updateId}
              />
            </div>
            <div className='mb-17'>
              {harvestedLines.map(line => (
                <div
                  className='mb-15 d-flex align-items-center'
                  key={line.line_id}
                >
                  <div className='mr-7'>
                    <Input
                      type='number'
                      label={
                        linesOption.find(x => Number(x.id) === line.line_id)
                          ?.label ?? ''
                      }
                      placeholder={translate(lang, 'Bags quantity')}
                      value={line.bags_quantity?.toString() ?? ''}
                      onChange={e =>
                        updateHarvestLine(
                          line.line_id,
                          'bags_quantity',
                          e.target.value,
                        )
                      }
                    />
                  </div>
                  <div className='ml-7 mr-7'>
                    <Dropdown
                      label='F / P'
                      value={!!line.is_full ? 'full' : 'partial'}
                      options={[
                        {
                          id: 'full',
                          value: 'full',
                          label: translate(lang, 'Full'),
                        },
                        {
                          id: 'partial',
                          value: 'partial',
                          label: translate(lang, 'Partial'),
                        },
                      ]}
                      onChange={v =>
                        updateHarvestLine(line.line_id, 'is_full', v === 'full')
                      }
                    />
                  </div>
                  <div className='ml-7'>
                    <Input
                      type='number'
                      label={translate(lang, 'Harvested length')}
                      value={line.line_length ?? ''}
                      onChange={e =>
                        updateHarvestLine(
                          line.line_id,
                          'line_length',
                          e.target.value,
                        )
                      }
                      unit='m'
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          {harvestedLines.length === 1 && !harvestedLines[0].is_full && (
            <div className='section'>
              <div className='mb-17'>
                <CheckboxButton
                  label={translate(lang, '_historical_cycle')}
                  checked={forInsert}
                  onChange={e => toggleForInsert(e.target.checked)}
                />
              </div>
              {!!ranges && (
                <div className='mb-32'>
                  <Dropdown
                    label={translate(lang, 'Season')}
                    options={ranges.map(x => ({
                      id: x.id.toString(),
                      value: x.id.toString(),
                      label: `${x.season_name} (${defaultDateFormat(
                        x.from_date,
                      )} - ${defaultDateFormat(x.to_date)})`,
                    }))}
                    value={formData.seeding_id?.toString() ?? undefined}
                    onChange={v => updateForm('seeding_id', Number(v))}
                  />
                </div>
              )}
            </div>
          )}
          <div className='section'>
            <div className='mb-17'>
              <CheckboxButton
                label={translate(lang, '_not_final')}
                checked={isMiddle}
                onChange={e => setIsMiddle(e.target.checked)}
              />
            </div>
            <div className='item-row mb-17'>
              <div className='item-cell'>
                <Input
                  label={translate(lang, 'Harvest Amount')}
                  type='number'
                  onChange={e => setTotAmount(e.target.value)}
                  value={totAmount}
                  unit='kg'
                />
              </div>
              <div className='item-cell'>
                {!isMiddle && (
                  <Input
                    label={translate(lang, 'Harvest Income')}
                    type='number'
                    onChange={e => setTotIncome(e.target.value)}
                    value={totIncome}
                    unit='$'
                  />
                )}
              </div>
            </div>
            <ExpensesForm
              title={translate(lang, 'Harvest Expenses')}
              data={harvestCosts}
              setData={setHarvestCosts}
              hideTotal={true}
            />
            <div className='item-row mt-7 strong'>
              <div className='item-cell'>{translate(lang, 'Totals')}</div>
              <div className='item-cell'>{`${formatNumber(
                Number(totAmount),
              )} kg`}</div>
              <div className='item-cell'>
                <div>{numberToMoneyStr(Number(totIncome))} ($)</div>
                <div>-{numberToMoneyStr(costSum)} ($)</div>
                <div>{numberToMoneyStr(Number(totIncome) - costSum)} ($)</div>
              </div>
              <div className='item-cell-half'></div>
            </div>
          </div>
          <div className='section'>
            <div className='item-row mb-17'>
              <div className='item-cell'>
                <Input
                  label={translate(lang, 'Number of rope bags')}
                  placeholder={translate(lang, 'Number of rope bags')}
                  type='number'
                  onChange={e =>
                    updateForm('rope_bags_quantity', e.target.value)
                  }
                  value={formData.rope_bags_quantity?.toString() ?? ''}
                />
              </div>
              <div className='item-cell'>
                <Input
                  label={translate(lang, 'Harvest number')}
                  placeholder={translate(lang, 'Harvest number')}
                  type='text'
                  onChange={e => updateForm('harvest_number', e.target.value)}
                  value={formData.harvest_number ?? ''}
                />
              </div>
            </div>
            <div className='item-row mb-17'>
              <div className='item-cell'>
                <Datepicker
                  label={translate(lang, 'Harvest complete date')}
                  defaultValue={formData.complete_date}
                  onChange={e =>
                    e && updateForm('complete_date', e.toDate().getTime())
                  }
                />
              </div>
              <div className='item-cell'>
                <Input
                  label={translate(lang, 'Port of unload')}
                  placeholder={translate(lang, 'Port of unload')}
                  type='text'
                  onChange={e => updateForm('port_of_unload', e.target.value)}
                  value={formData.port_of_unload ?? ''}
                />
              </div>
            </div>
            <div className='item-row mb-17'>
              <div className='item-cell'>
                <Input
                  label={translate(lang, 'Vessel')}
                  placeholder={translate(lang, 'Vessel')}
                  type='text'
                  onChange={e => updateForm('vessel', e.target.value)}
                  value={formData.vessel ?? ''}
                />
              </div>
              <div className='item-cell'>
                <Input
                  label={translate(lang, 'Company')}
                  placeholder={translate(lang, 'Company')}
                  type='text'
                  onChange={e => updateForm('company', e.target.value)}
                  value={formData.company ?? ''}
                />
              </div>
            </div>
            <div className='mb-17'>
              <Input
                label={translate(lang, 'Comment')}
                placeholder={translate(lang, 'Comment')}
                type='textarea'
                onChange={e => updateForm('comment', e.target.value)}
                value={formData.comment ?? ''}
                name='comment'
              />
            </div>
          </div>
          <div className='section'>
            <div className='mb-17'>
              <PDFWall
                label={translate(lang, 'Attach PDF File')}
                fileList={pdfFiles}
                onChange={v => setPdfFiles(v)}
              />
            </div>
          </div>
          <div className='modal-button d-flex justify-content-end align-items-center'>
            <Button
              width='small'
              className='rsp-btn'
              size={2}
              type='fill'
              color='red'
              onClick={onClose}
              disabled={disabled}
            >
              {translate(lang, 'Cancel')}
            </Button>
            <Button
              width='small'
              size={2}
              type='fill'
              color='green'
              className='rsp-btn ml-16'
              onClick={confirmClick}
              disabled={disabled}
            >
              {translate(lang, 'Confirm')}
            </Button>
          </div>
        </div>
      ) : (
        <div className='grower-harvest-modal wrap'>
          <GrowerInvoiceCreateView
            farmID={lineData?.farm_id}
            invoice={invoiceDetail()}
            onClose={() => onConfirm(submittedData)}
            showButton={!!submittedData}
          />
          {!submittedData && (
            <div className='modal-button d-flex justify-content-end align-items-center'>
              <Button
                width='small'
                size={2}
                type='fill'
                color='red'
                className='rsp-btn'
                onClick={() => setShowConfirm(false)}
                disabled={disabled}
              >
                {translate(lang, 'Go Back')}
              </Button>
              <Button
                width='small'
                size={2}
                type='fill'
                color='green'
                className='rsp-btn ml-16'
                onClick={confirmClick}
                disabled={disabled}
              >
                {translate(lang, 'Confirm')}
              </Button>
            </div>
          )}
        </div>
      )}
      <ModalFeedbackView />
    </Modal>
  );
};

export default MusselGrowerHarvestModal;
