import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Radio } from 'antd';
import { showFeedback } from '../../../store/ui/ui.actions';
import {
  Button,
  CheckboxButton,
  CloseIcon,
  Datepicker,
  Dropdown,
  Input,
  RadioButton,
  Subtitle,
} from '../../shared';
import moment from 'moment';
import { sendSingleRequest } from '../../../apis';
import { loadFarmsData } from '../../../store/farms/farms.actions';
import { IXeroInvoice } from '../../../entities/subscription.entities';
import GrowerInvoiceCreateView from '../../budget/GrowerInvoiceCreateView';
import ModalFeedbackView from '../../shared/feedback/ModalFeedbackView';
import {
  defaultDateFormat,
  toMillisecond,
  toSecond,
} from '../../../util/toggleSecondMillisecond';
import { ICustomValue } from '../../../entities/automation.entities';
import { Link } from 'react-router-dom';
import { IMusselSeeding } from '../../../entities/growing.entities';
import {
  IMusselLineDetail,
  IMusselLineResource,
} from '../../../entities/farms.entities';
import { SpatSelectLabel } from '../SeedingModal';
import ExpensesForm from '../../grower-views/ExpensesForm';
import { IExpenseRequest } from '../../../entities/budget.entities';
import { selectLang } from '../../../store/ui/ui.selector';
import { translate } from '../../../lib/lang.helper';
import { addUtilData } from '../../../store/utils/utils.actions';
import {
  SelectMusselFarmsByFarmID,
  SelectMusselSpatsByFarmID,
  SelectSeasonsByFarmID,
  SelectSeedingFields,
} from '../../../store/extra/extra.selector';

interface ISRange {
  id: number;
  season_name: string;
  from_date: number;
  to_date: number;
  seed_count: number;
  harvest_count: number;
}

interface IFormData {
  parent_id?: number;
  season_name: string;
  spat_size: string;
  density: string;
  density_unit: 'spat per m' | 'kgs per m';
  planned_date_seed: number;
  planned_date_harvest: number;
  drop: string;
  submersion: string;
  spacing: string;
  floats: string;
  comment: string | null;
  budget_unit_amount: number | null;
  budget_unit_price: number | null;
  custom_values?: ICustomValue[];
  spat_amount?: number;
  line_length?: number;
  spat_storage_id?: number;
}

const defaultForm: IFormData = {
  season_name: '',
  spat_size: '',
  density: '',
  density_unit: 'spat per m',
  planned_date_seed: moment().toDate().getTime(),
  planned_date_harvest: moment().toDate().getTime(),
  comment: null,
  budget_unit_amount: null,
  budget_unit_price: null,
  drop: '',
  submersion: '',
  spacing: '',
  floats: '',
};

interface ILineItem {
  line_id: number;
  new_spat: 'NEW' | 'EXISTING';
  spat_storage_id?: number;
  spat_source: string;
  spat_amount: number | null;
  line_length: number | null;
}

const spatAmountPlaceholder = (id: number | undefined, spats: any) => {
  const spat = spats.find((y: any) => y.id === id);
  return spat ? `Available: ${spat.available_weight}` : '';
};

interface Props {
  visible: boolean;
  lineData?: IMusselLineResource;
  seedData?: IMusselSeeding;
  onClose: (d: any) => void;
  onConfirm: (d: any) => void;
  overConfirm?: (d: any) => Promise<any>;
  className?: string;
  onlyView?: boolean;
  data?: any;
  warningMessage?: string;
}

const MusselGrowerSeedModal = ({
  visible,
  lineData,
  seedData,
  onClose,
  onConfirm,
  overConfirm,
  className,
  onlyView,
  data: paramData,
  warningMessage,
}: Props) => {
  const dispatch = useDispatch<any>();

  const lang = useSelector(selectLang);
  const farms = SelectMusselFarmsByFarmID(lineData?.farm_id);
  const customFields = SelectSeedingFields(lineData?.farm_id);
  const spatStorages = SelectMusselSpatsByFarmID(lineData?.farm_id);
  const seasonData = SelectSeasonsByFarmID(lineData?.farm_id).map(x => ({
    id: x.id.toString(),
    label: x.name,
    value: x.name,
  }));
  const linesOption =
    farms
      .reduce((p, c) => [...p, ...c.lines], [] as IMusselLineResource[])
      .map(x => ({
        id: x.id.toString(),
        value: x.id.toString(),
        label: `${x.farm_name} - ${x.line_name}`,
      })) ?? [];
  const isEdit = !!seedData && !!seedData.id;
  const spatOptions = spatStorages.map(x => ({
    id: x.id.toString(),
    value: x.id.toString(),
    label: SpatSelectLabel(x),
  }));

  const [disabled, setDisabled] = useState(false);
  const [seedLines, setSeedLines] = useState<ILineItem[]>([]);
  const [formData, setFormData] = useState<IFormData>(defaultForm);
  const [seedItems, setSeedItems] = useState<IExpenseRequest[]>([
    { name: '', quantity: 1, amount: 0 },
  ]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [seasonType, setSeasonType] = useState<'old' | 'new'>('old');
  const [submittedData, setSubmittedData] = useState<any>();
  const [forInsert, setForInsert] = useState(false);
  const [ranges, setRanges] = useState<ISRange[]>();
  const [customValues, setCustomValues] = useState(
    customFields.map(x => {
      const d =
        seedData && seedData.custom_values
          ? seedData.custom_values.find(
              (y: ICustomValue) => y.field_id === x.id,
            )
          : null;
      return {
        input_type: x.input_type,
        field_label: x.name,
        options: x.options
          ?.split(',')
          .map(t => ({ id: t, label: t, value: t })),
        field_id: x.id,
        field_val: d?.field_val ?? '',
        data_type: x.data_type,
        unit: x.unit,
      };
    }),
  );

  const showError = (message: string) =>
    dispatch(
      showFeedback({
        isMessageModal: true,
        type: 'error',
        message: translate(lang, message),
      }),
    );
  const updateForm = (key: keyof IFormData, val: any) => {
    if (key === 'budget_unit_amount' || key === 'budget_unit_price') {
      val = val.length <= 0 ? null : Number(val);
    } else if (key === 'spat_amount' || key === 'line_length') {
      val = val.length <= 0 ? undefined : Number(val);
    } else if (key === 'spat_storage_id') {
      val = val.length <= 0 ? undefined : Number(val);
    }
    setFormData(prv => ({ ...prv, [key]: val }));
  };
  const changeIsNewSpat = (i: number, val: string) => {
    let tmp = [...seedLines];
    tmp[i].new_spat = val as any;
    if (val === 'NEW') {
      tmp[i].spat_storage_id = undefined;
    } else {
      tmp[i].spat_source = '';
    }
    setSeedLines(tmp);
  };
  const updateSeedLine = (
    i: number,
    key: 'spat_id' | 'spat_source' | 'spat_amount' | 'line_length',
    val: string,
  ) => {
    if (key === 'spat_id') {
      setSeedLines(
        seedLines.map((x, j) =>
          j === i ? { ...x, spat_storage_id: Number(val) } : x,
        ),
      );
    } else {
      const v =
        key === 'spat_source' ? val : val.length <= 0 ? null : Number(val);
      setSeedLines(seedLines.map((x, j) => (j === i ? { ...x, [key]: v } : x)));
    }
  };
  const handleChangeSeedLine = (v: string[]) => {
    let tmp = seedLines.filter(x => v.includes(x.line_id.toString()));
    for (let i of v) {
      if (tmp.some(x => x.line_id === Number(i))) continue;
      tmp.push({
        line_id: Number(i),
        spat_source: '',
        new_spat: 'EXISTING',
        spat_amount: null,
        line_length: null,
      });
    }
    setSeedLines(tmp);
  };
  const validData = () => {
    if (!isEdit && seedLines.length <= 0) {
      showError('Please select seed lines');
      return null;
    }
    if (!isEdit && seedLines.some(x => !x.spat_source && !x.spat_storage_id)) {
      showError('Empty spat source is not allowed');
      return null;
    }
    if (seedLines.some(x => !x.line_length)) {
      showError('Empty seeded longline length is not allowed');
      return null;
    }
    if (!isEdit && seedLines.some(x => !x.spat_amount)) {
      showError('Empty spat amount is not allowed');
      return null;
    }
    for (let sx of seedLines.filter(x => x.new_spat === 'EXISTING')) {
      const ln = linesOption.find(x => Number(x.id) === sx.line_id);
      if (!ln) continue;

      const sp = spatStorages.find(x => x.id === sx.spat_storage_id);
      if (!sp) {
        showError(
          translate(lang, 'Invalid spat storage for line %s', ln.label),
        );
        return null;
      }
      if (!sx.spat_amount) {
        showError(translate(lang, 'Invalid spat amount for line %s', ln.label));
        return null;
      }
      if (!isEdit && sx.spat_amount > (sp.available_weight ?? 0)) {
        showError(
          translate(
            lang,
            '_max_weight_line_spat',
            ln.label,
            sp.source,
            sp.available_weight ?? '',
          ),
        );
        return null;
      }
    }
    if (!formData.season_name) {
      showError('Empty season is not allowed');
      return null;
    }
    if (
      !isEdit &&
      seedItems.length <= 0 &&
      !window.confirm(
        translate(lang, 'Are you sure to enter empty expenses of spats?'),
      )
    ) {
      showError('Empty spat items are not allowed');
      return null;
    }
    if (seedItems.some(x => !x.name || !x.amount || !x.unit_price)) {
      showError('Please input correct spat items description and values');
      return null;
    }
    const expenses = seedItems.map(x => ({
      name: x.name,
      amount: Number(x.amount),
      unit_price: Number(x.unit_price),
      quantity: Number(x.quantity),
    }));
    let result: any = {
      ...formData,
      planned_date_seed: toSecond(formData.planned_date_seed),
      planned_date_harvest: toSecond(formData.planned_date_harvest),
      drop: Number(formData.drop),
      submersion: Number(formData.submersion),
      spacing: Number(formData.spacing),
      floats: Number(formData.floats),
      spat_size: Number(formData.spat_size),
      density: Number(formData.density),
      lines: seedLines.map(x => ({
        line_id: x.line_id,
        spat_storage_id: x.spat_storage_id,
        spat_source: x.spat_source,
        spat_amount: Number(x.spat_amount),
        line_length: Number(x.line_length),
      })),
      expenses,
    };
    if (isEdit) {
      delete result.spat_source;
    }
    const cvs = customValues
      .filter(x => !!x.field_val)
      .map(({ field_id, field_val }) => ({ field_id, field_val }));
    if (cvs.length > 0) {
      result.custom_values = cvs;
    }
    return result;
  };
  const invoiceDetail = (): IXeroInvoice => {
    let line_items: any = [];
    if (isEdit) {
      if (!lineData || !seedData) {
        window.alert('Unexpected error! Please contact with support team!');
        throw new Error('Unexpected error!');
      }
      for (let item of seedItems) {
        const quantity = Number(item.quantity) ?? 1;
        line_items.push({
          item_name: `${lineData.farm_name} - ${lineData.line_name}`,
          description: item.name,
          quantity,
          unit_price: Number(item.unit_price),
        });
      }
    } else {
      const totCnt = seedLines.length;
      for (let lID of seedLines) {
        const line = linesOption.find(t => Number(t.id) === lID.line_id);
        if (!line) continue;
        for (let item of seedItems) {
          const quantity =
            item.quantity && Number(item.quantity) ? Number(item.quantity) : 1;
          line_items.push({
            item_name: line.label,
            description: item.name,
            quantity: quantity / totCnt,
            unit_price: Number(item.amount) / quantity,
          });
        }
      }
    }
    return {
      reference: 'Seed Invoice',
      contact_id: '',
      line_items,
      type: 'ACCPAY',
    };
  };
  const toggleForInsert = async (c: boolean) => {
    if (c) {
      const response = await sendSingleRequest(
        {},
        'GET',
        `api/farm/line/lines/${seedLines[0].line_id}`,
        true,
      );
      if (response.status) {
        let dt: ISRange[] = [];
        const lineSource: IMusselLineDetail = response.data;
        for (let row of lineSource.cycles) {
          const lstH = row.harvests.find(x => x.is_full);
          dt.push({
            id: row.main_seed.id,
            season_name: row.main_seed.season_name,
            from_date: row.main_seed.planned_date_seed,
            to_date: lstH?.complete_date ?? row.main_seed.planned_date_harvest,
            seed_count: row.seedings.length,
            harvest_count: row.harvests.length,
          });
        }
        setRanges(dt);
      }
    } else {
      updateForm('parent_id', undefined);
      setRanges(undefined);
    }
    setForInsert(c);
  };
  const closeInvoice = () => {
    const tmp = submittedData;
    setSubmittedData(undefined);
    setShowConfirm(false);
    onConfirm(tmp);
  };
  const confirmClick = async () => {
    const data = validData();
    if (!data) return;

    if (overConfirm) {
      setDisabled(true);
      if (!overConfirm(data)) {
        setDisabled(false);
      }
      return;
    }

    if (!showConfirm) {
      setShowConfirm(true);
      return;
    }

    setDisabled(true);
    if (!seasonData.some(x => x.value === data.season_name)) {
      dispatch(addUtilData('season', data.season_name));
    }
    const res = isEdit
      ? await sendSingleRequest(
          data,
          'PUT',
          `api/farm/line/seeding/${seedData?.id}`,
          true,
        )
      : await sendSingleRequest(
          data,
          'POST',
          'api/farm/line/multiline-seeding',
          true,
        );
    setDisabled(false);
    if (res.status) {
      dispatch(
        showFeedback({
          isMessageModal: true,
          message: translate(
            lang,
            res.data?.message
              ? res.data.message
              : isEdit
              ? 'Successfully updated'
              : 'Successfully seeded',
          ),
          type: 'success',
        }),
      );
      setSubmittedData(res.data);
      dispatch(loadFarmsData());
    } else {
      showError(translate(lang, res.data?.message ?? 'Failed to seed'));
    }
  };
  const updateCustomValue = (fieldID: number, val: string) => {
    let tmp = [...customValues];
    const i = tmp.findIndex(x => x.field_id === fieldID);
    if (i >= 0) {
      tmp[i].field_val = val;
    }
    setCustomValues(tmp);
  };

  useEffect(() => {
    let tmpSeedLines = [...seedLines];
    if (lineData) {
      if (!tmpSeedLines.some(x => x.line_id === lineData.id)) {
        tmpSeedLines.push({
          line_id: lineData.id,
          line_length: null,
          new_spat: 'EXISTING',
          spat_amount: null,
          spat_source: '',
        });
      }
    }
    if (seedData && seedData.id) {
      if (seedData.expense_items) {
        const items = seedData.expense_items.map(x => ({
          name: x.name,
          amount: x.amount,
          unit_price: Math.round((x.amount / (x.quantity ?? 1)) * 100) / 100,
          quantity: x.quantity ?? 0,
        }));
        setSeedItems(items);
      }
      let tmp = { ...formData };
      for (let k of Object.keys(formData)) {
        let val = (seedData as any)[k];
        if (k === 'planned_date_seed' || k === 'planned_date_harvest') {
          val = val * 1000;
        }
        if ((seedData as any)[k] !== undefined) (tmp as any)[k] = val;
      }
      const i = tmpSeedLines.findIndex(x => x.line_id === lineData?.id);
      if (i > -1) {
        tmpSeedLines[i].new_spat = seedData.spat_storage ? 'EXISTING' : 'NEW';
        tmpSeedLines[i].spat_storage_id = seedData.spat_storage?.id;
        tmpSeedLines[i].spat_source = seedData.spat_storage?.source ?? '';
        tmpSeedLines[i].line_length = seedData.line_length;
        tmpSeedLines[i].spat_amount = seedData.spat_amount;
      }
      if (seedData.spat_storage) {
        tmp.spat_storage_id = seedData.spat_storage.id;
      }
      if (seedData.spat_amount) {
        tmp.spat_amount = seedData.spat_amount;
      }
      if (seedData.line_length) {
        tmp.line_length = seedData.line_length;
      }
      setFormData(tmp);
    }
    setSeedLines(tmpSeedLines);
  }, [lineData, seedData]);

  useEffect(() => {
    if (paramData) {
      let tmpLines: ILineItem[] = [];
      const {
        lines,
        custom_values,
        expenses,
        planned_date_seed,
        planned_date_harvest,
        line_length,
        spat_amount,
        spat_storage_id,
        parent_id,
        ...params
      } = paramData;
      if (lines && lines.length > 0) {
        for (const ln of lines) {
          const spat = spatStorages.find(x => x.id === ln.spat_storage_id);
          tmpLines.push({
            line_id: ln.line_id,
            new_spat: spat ? 'EXISTING' : 'NEW',
            spat_storage_id: spat?.id,
            spat_source: ln.spat_source,
            spat_amount: ln.spat_amount,
            line_length: ln.line_length,
          });
        }
        setSeedLines(tmpLines);
      } else if (lineData) {
        setSeedLines([
          {
            line_id: lineData.id,
            line_length: line_length ?? null,
            spat_amount: spat_amount ?? null,
            new_spat: spat_storage_id ? 'EXISTING' : 'NEW',
            spat_storage_id: spat_storage_id ?? undefined,
            spat_source: '',
          },
        ]);
      }
      if (custom_values) {
        const tmpCustom = customFields.map(x => {
          const d = custom_values.find(
            (y: ICustomValue) => y.field_id === x.id,
          );
          return {
            input_type: x.input_type,
            field_label: x.name,
            options: x.options
              ?.split(',')
              .map(t => ({ id: t, label: t, value: t })),
            field_id: x.id,
            field_val: d?.field_val ?? '',
            data_type: x.data_type,
            unit: x.unit,
          };
        });
        setCustomValues(tmpCustom);
      }

      if (expenses) {
        setSeedItems(expenses);
      }
      setFormData({
        ...params,
        planned_date_seed: toMillisecond(planned_date_seed),
        planned_date_harvest: toMillisecond(planned_date_harvest),
      });
    }
  }, [paramData, lineData]);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      className={className}
      closable
      maskClosable={false}
      closeIcon={<CloseIcon />}
      width={900}
      footer={null}
    >
      {!showConfirm ? (
        <div className='grower-seed-modal wrap'>
          <div className='d-flex align-items-center mb-17'>
            <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
              {translate(lang, 'INVOICE DETAILS')}
            </Subtitle>
          </div>
          {!!warningMessage && (
            <div className='header-alert'>{warningMessage}</div>
          )}
          {!isEdit ? (
            <>
              <div className='section'>
                <div className='mb-17'>
                  <Dropdown
                    mode='multiple'
                    label={translate(lang, 'Select seeded lines')}
                    placeholder={translate(lang, 'Select seeded lines')}
                    options={linesOption}
                    value={seedLines.map(x => x.line_id.toString()) as any}
                    onChange={(v: any) => handleChangeSeedLine(v)}
                    showSearch={true}
                  />
                </div>
                {seedLines.map((x, i) => (
                  <div key={i}>
                    <div className='item-row'>
                      <Subtitle
                        color='black-2'
                        align='left'
                        size={5}
                        fontWeight={500}
                      >
                        {translate(
                          lang,
                          'Spat and Longline data of %s',
                          linesOption.find(y => Number(y.value) === x.line_id)
                            ?.label ?? '',
                        )}
                      </Subtitle>
                    </div>
                    <div className='item-row'>
                      <div className='item-cell'>
                        <Dropdown
                          label={translate(lang, 'Is new spat?')}
                          options={[
                            {
                              id: 'N',
                              value: 'NEW',
                              label: translate(lang, 'New'),
                            },
                            {
                              id: 'E',
                              value: 'EXISTING',
                              label: translate(lang, 'Existing'),
                            },
                          ]}
                          value={x.new_spat}
                          onChange={v => changeIsNewSpat(i, v)}
                        />
                      </div>
                      <div className='item-cell'>
                        {x.new_spat === 'NEW' ? (
                          <Input
                            type='text'
                            onChange={e =>
                              updateSeedLine(i, 'spat_source', e.target.value)
                            }
                            value={x.spat_source}
                            label={translate(lang, 'Spat Source')}
                            disabled={isEdit}
                          />
                        ) : (
                          <Dropdown
                            label={translate(lang, 'Select spat source')}
                            options={spatOptions}
                            value={x.spat_storage_id?.toString()}
                            onChange={v => updateSeedLine(i, 'spat_id', v)}
                          />
                        )}
                      </div>
                    </div>
                    <div className='item-row mb-17'>
                      <div className='item-cell'>
                        <Input
                          type='number'
                          label={translate(lang, 'Spat amount')}
                          placeholder={spatAmountPlaceholder(
                            x.spat_storage_id,
                            spatStorages,
                          )}
                          onChange={e =>
                            updateSeedLine(i, 'spat_amount', e.target.value)
                          }
                          value={x.spat_amount?.toString() ?? ''}
                          unit='kg'
                          disabled={isEdit}
                        />
                      </div>
                      <div className='item-cell'>
                        <Input
                          type='number'
                          label={translate(lang, 'Longline length')}
                          value={x.line_length?.toString() ?? ''}
                          onChange={e =>
                            updateSeedLine(i, 'line_length', e.target.value)
                          }
                          unit='m'
                          disabled={isEdit}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {seedLines.length === 1 && (
                <div className='section'>
                  <div className='mb-17'>
                    <CheckboxButton
                      label={translate(lang, '_historical_cycle')}
                      checked={forInsert}
                      onChange={e => toggleForInsert(e.target.checked)}
                    />
                  </div>
                  {!!ranges && (
                    <div className='mb-32'>
                      <Dropdown
                        label={translate(lang, 'Season')}
                        options={ranges.map(x => ({
                          id: x.id.toString(),
                          value: x.id.toString(),
                          label: `${x.season_name} (${defaultDateFormat(
                            x.from_date,
                          )} - ${defaultDateFormat(x.to_date)})`,
                        }))}
                        value={formData.parent_id?.toString() ?? undefined}
                        onChange={v => updateForm('parent_id', Number(v))}
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          ) : (
            <div className='section'>
              <div className='item-row mb-17'>
                <div className='item-cell'>
                  <Dropdown
                    label={translate(lang, 'Select spat source')}
                    options={spatOptions}
                    value={formData.spat_storage_id?.toString()}
                    onChange={v => updateForm('spat_storage_id', v)}
                  />
                </div>
                <div className='item-cell'>
                  <Input
                    type='number'
                    label={translate(lang, 'Spat amount')}
                    placeholder={spatAmountPlaceholder(
                      formData.spat_storage_id,
                      spatStorages,
                    )}
                    onChange={e => updateForm('spat_amount', e.target.value)}
                    value={formData.spat_amount?.toString() ?? ''}
                    unit='kg'
                  />
                </div>
                <div className='item-cell'>
                  <Input
                    type='number'
                    label={translate(lang, 'Longline length')}
                    value={formData.line_length?.toString() ?? ''}
                    onChange={e => updateForm('line_length', e.target.value)}
                    unit='m'
                  />
                </div>
              </div>
            </div>
          )}
          <div className='section'>
            <div className='item-row mb-17'>
              <div className='item-cell'>
                <Radio.Group
                  className='d-flex mb-16'
                  onChange={e => setSeasonType(e.target.value)}
                  value={seasonType}
                >
                  <RadioButton
                    label={translate(lang, 'Existing season')}
                    value='old'
                  />
                  <RadioButton
                    className='ml-34'
                    label={translate(lang, 'New season')}
                    value='new'
                  />
                </Radio.Group>
                <div>
                  {seasonType === 'old' ? (
                    <Dropdown
                      label={translate(lang, 'Season name')}
                      placeholder={translate(lang, 'Choose Season')}
                      className='mr-16 w-100'
                      onChange={value => updateForm('season_name', value)}
                      options={seasonData}
                      value={formData.season_name}
                    />
                  ) : (
                    <Input
                      label={translate(lang, 'Season name')}
                      type='text'
                      onChange={e => updateForm('season_name', e.target.value)}
                      value={formData.season_name}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className='item-row mb-17'>
              <div className='item-cell'>
                <Input
                  label={translate(lang, 'Floats')}
                  type='number'
                  onChange={e => updateForm('floats', e.target.value)}
                  value={formData.floats}
                />
              </div>
              <div className='item-cell'>
                <Input
                  label={translate(lang, 'Spat size')}
                  type='number'
                  onChange={e => updateForm('spat_size', e.target.value)}
                  value={formData.spat_size}
                />
              </div>
              <div className='item-cell'>
                <Input
                  label={translate(lang, 'Drop')}
                  type='number'
                  onChange={e => updateForm('drop', e.target.value)}
                  value={formData.drop}
                />
              </div>
            </div>
            <div className='item-row mb-17'>
              <div className='item-cell'>
                <Input
                  label={translate(lang, 'Submersion')}
                  type='number'
                  onChange={e => updateForm('submersion', e.target.value)}
                  value={formData.submersion}
                />
              </div>
              <div className='item-cell'>
                <Input
                  label={translate(lang, 'Spacing')}
                  type='number'
                  onChange={e => updateForm('spacing', e.target.value)}
                  value={formData.spacing}
                />
              </div>
              <div className='item-cell'>
                <Input
                  label={translate(lang, 'Density')}
                  type='number'
                  onChange={e => updateForm('density', e.target.value)}
                  value={formData.density}
                />
              </div>
            </div>
            <div className='item-row mb-17'>
              <div className='item-cell'>
                <Datepicker
                  label={translate(lang, 'Date seed')}
                  defaultValue={formData.planned_date_seed}
                  onChange={e =>
                    e && updateForm('planned_date_seed', e.toDate().getTime())
                  }
                />
              </div>
              <div className='item-cell'>
                <Datepicker
                  label={translate(lang, 'Planned harvest date')}
                  defaultValue={formData.planned_date_harvest}
                  onChange={e =>
                    e &&
                    updateForm('planned_date_harvest', e.toDate().getTime())
                  }
                />
              </div>
            </div>
            <div className='item-row mb-17'>
              <div className='item-cell'>
                <Input
                  type='number'
                  label={translate(lang, 'Budgeted kg/m')}
                  unit='kg/m'
                  onChange={e =>
                    updateForm('budget_unit_amount', e.target.value)
                  }
                  value={formData.budget_unit_amount?.toString() ?? ''}
                />
              </div>
              <div className='item-cell'>
                <Input
                  type='number'
                  label={translate(lang, 'Budgeted $/m')}
                  unit='$/m'
                  onChange={e =>
                    updateForm('budget_unit_price', e.target.value)
                  }
                  value={formData.budget_unit_price?.toString() ?? ''}
                />
              </div>
            </div>
            {customValues.map(x => (
              <div key={x.field_id} className='mb-17'>
                {x.input_type === 'select' ? (
                  <>
                    <Dropdown
                      label={x.field_label}
                      options={x.options ?? []}
                      value={x.field_val}
                      onChange={v => updateCustomValue(x.field_id, v)}
                    />
                    {(!x.options || x.options.length <= 0) && (
                      <Link to={'/custom-fields'}>
                        <span style={{ color: '#008ed1' }}>
                          {translate(lang, 'Set up field')}
                        </span>
                      </Link>
                    )}
                  </>
                ) : (
                  <Input
                    type={x.data_type === 'number' ? 'number' : 'text'}
                    label={x.field_label}
                    value={x.field_val ?? ''}
                    onChange={e =>
                      updateCustomValue(x.field_id, e.target.value)
                    }
                    unit={x.unit}
                  />
                )}
              </div>
            ))}
          </div>
          <ExpensesForm
            title={translate(lang, 'Seed items')}
            className='section'
            data={seedItems}
            setData={setSeedItems}
          />
          <div className='section'>
            <div className='mb-17'>
              <Input
                label={translate(lang, 'Comment')}
                placeholder={translate(lang, 'Please write description here')}
                type='textarea'
                onChange={e => updateForm('comment', e.target.value)}
                value={formData.comment ?? ''}
                name='comment'
              />
            </div>
          </div>
          <div className='modal-button d-flex justify-content-end align-items-center'>
            <Button
              width='small'
              size={2}
              type='fill'
              color='red'
              className='rsp-btn'
              onClick={onClose}
              disabled={disabled}
            >
              {translate(lang, 'Cancel')}
            </Button>
            <Button
              width='small'
              size={2}
              type='fill'
              color='green'
              className='rsp-btn ml-16'
              onClick={confirmClick}
              disabled={disabled || onlyView}
            >
              {translate(lang, 'Confirm')}
            </Button>
          </div>
        </div>
      ) : (
        <div className='grower-seed-modal wrap'>
          <GrowerInvoiceCreateView
            farmID={lineData?.farm_id}
            invoice={invoiceDetail()}
            onClose={closeInvoice}
            showButton={!!submittedData}
          />
          {!submittedData && (
            <div className='modal-button d-flex justify-content-end align-items-center'>
              <Button
                width='small'
                className='rsp-btn'
                size={2}
                type='fill'
                color='red'
                onClick={() => setShowConfirm(false)}
                disabled={disabled}
              >
                {translate(lang, 'Go Back')}
              </Button>
              <Button
                width='small'
                size={2}
                type='fill'
                color='green'
                className='rsp-btn ml-16'
                onClick={confirmClick}
                disabled={disabled}
              >
                {translate(lang, 'Confirm')}
              </Button>
            </div>
          )}
        </div>
      )}
      <ModalFeedbackView />
    </Modal>
  );
};

export default MusselGrowerSeedModal;
