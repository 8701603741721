import { Modal, Table } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactImageGallery from 'react-image-gallery';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { sendSingleRequest } from '../../apis';
import {
  BreadcrumbComponent,
  Button,
  ModalComponent,
  Spinner,
  Title,
} from '../../components/shared';
import {
  IAssessmentResource,
  IFarmProperty,
  ILineProperty,
  IMaintenanceResource,
  ISeedInventoryResource,
} from '../../entities/farms.entities';
import getBreadcrumbMenu from '../../util/BreadcrumbMenu';
import { defaultUnitLength, rangeLabel } from '../../util/farmUtil';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import { showFeedback } from '../../store/ui/ui.actions';
import HarvestsTable from '../../components/lines/HarvestsTable';
import TimelineView from '../../components/lines/TimelineView';
import {
  IHarvestResource,
  IMusselHarvest,
  IMusselSeeding,
  IOysterHarvest,
  IOysterSeeding,
  ISeaweedHarvest,
  ISeaweedSeeding,
  ISeedingResource,
} from '../../entities/growing.entities';
import MaintenanceTable from '../../components/lines/MaintenanceTable';
import MusselHarvestHeader from './MusselHarvestHeader';
import OysterHarvestHeader from './OysterHarvestHeader';
import SeaweedHarvestHeader from './SeaweedHarvestHeader';
import { TBusinessType } from '../../entities/general.entities';
import HarvestTrackModal from '../../components/track-modals/HarvestTrackModal';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { TLang } from '../../entities/ui.entities';
import { selectAllFarms } from '../../store/extra/extra.selector';

interface IDataResource {
  farm: IFarmProperty;
  line: ILineProperty;
  seedings: ISeedingResource[];
  maintenances: IMaintenanceResource[];
  assessments: IAssessmentResource[];
  inventory_seeds: ISeedInventoryResource[];
  harvests: IHarvestResource[];
  pdf_form?: any;
  main_seeding_id: number;
  main_harvest_id: number;
}

const seedColumns = (
  type: TBusinessType,
  method: string | null,
  lang: TLang | undefined,
) => {
  let result: any = [
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID',
      render: (x: any) => <span>{x}</span>,
    },
    {
      key: 'planned_date_seed',
      dataIndex: 'planned_date_seed',
      title: translate(lang, 'Date seeded'),
      render: (x: any) => <span>{defaultDateFormat(x)}</span>,
    },
    {
      key: 'planned_date_harvest',
      dataIndex: 'planned_date_harvest',
      title: translate(lang, 'Planned date harvest'),
      render: (x: any) => <span>{defaultDateFormat(x)}</span>,
    },
    {
      key: 'season_name',
      dataIndex: 'season_name',
      title: translate(lang, 'Season'),
      render: (x: string) => <span>{x}</span>,
    },
  ];
  if (type === 'MUSSEL') {
    result.push(
      {
        key: 'line_length',
        title: translate(lang, 'Longline length'),
        render: (x: IMusselSeeding) => (
          <span>{defaultUnitLength(x.line_length, 'm', '-')}</span>
        ),
      },
      {
        key: 'spat_storage',
        dataIndex: '',
        title: translate(lang, 'Spats'),
        render: (x: IMusselSeeding) => (
          <span>
            {x.spat_storage
              ? `${x.spat_storage.source} - ${x.spat_amount} kg`
              : ''}
          </span>
        ),
      },
      {
        key: 'drop',
        dataIndex: 'drop',
        title: translate(lang, 'Drop'),
        render: (x: any) => <span>{x === null ? '-' : x}</span>,
      },
      {
        key: 'submersion',
        dataIndex: 'submersion',
        title: translate(lang, 'Submersion'),
        render: (x: any) => <span>{x === null ? '-' : x}</span>,
      },
    );
  } else if (type === 'OYSTER') {
    result.push(
      {
        key: 'basket_count',
        title: translate(lang, '%s count', method),
        render: (x: IOysterSeeding) => <span>{x.basket_count ?? ''}</span>,
      },
      {
        key: 'spat_storage',
        dataIndex: '',
        title: translate(lang, 'Spats'),
        render: (x: IOysterSeeding) => (
          <span>
            {x.spat_storage
              ? `${x.spat_storage.source} - ${x.spat_amount_dz} dz`
              : ''}
          </span>
        ),
      },
    );
  }
  result.push(
    {
      key: 'spat_size',
      dataIndex: '',
      title: translate(lang, 'Spat Size'),
      render: (x: ISeedingResource) => (
        <span>{rangeLabel(x.spat_size, x.spat_size_max, 'mm')}</span>
      ),
    },
    {
      key: 'density',
      title: translate(lang, 'Density'),
      render: (x: any) => <span>{rangeLabel(x.density, x.density_max)}</span>,
    },
  );
  return result;
};

interface IGalleryImages {
  original: string;
  thumbnail: string;
}

const formDownload = (pdfFile: any) => {
  if (!pdfFile) return;
  const bc = atob(pdfFile);
  const bn = bc.split('').map(x => x.charCodeAt(0));
  const ba = new Uint8Array(bn);
  const blob = new Blob([ba], { type: 'application/pdf' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = 'harvest-form.pdf';
  link.click();
  window.URL.revokeObjectURL(link.href);
};

const HarvestDetailPage = () => {
  const dispatch = useDispatch();

  const params = useParams<{ harvestId: string }>();
  const breadcrumbItems = getBreadcrumbMenu('HARVEST_DETAIL', {
    HARVEST_ID: params.harvestId,
  });
  const farmsData = useSelector(selectAllFarms);
  const lang = useSelector(selectLang);

  const [harvestData, setHarvestData] = useState<IDataResource>();
  const [asPhotoModalVisible, setAsPhotoModalVisible] = useState(false);
  const [assessPhotos, setAssessPhotos] = useState<Array<IGalleryImages>>([]);
  const [infoShow, setInfoShow] = useState(false);
  const [infoText, setInfoText] = useState('');
  const [visTrack, setVisTrack] = useState(false);
  const [tagLogs, setTagLogs] = useState<any[]>([]);

  const loadData = useCallback(async () => {
    const res = await sendSingleRequest(
      { require_pdf: true, with_harvest_spats: true, with_history: true },
      'GET',
      `api/farm/line/harvest-data?harvest_id=${params.harvestId}`,
      true,
    );
    if (res.status) {
      const lsp = await sendSingleRequest(
        {},
        'GET',
        `api/farm/line/tag-logs/${res.data.line.id}`,
        true,
      );
      let logs: any[] = [];
      if (lsp.status) {
        for (let x of lsp.data) {
          if (!x.is_on) {
            logs.push(
              {
                id: x.id,
                tag: x.tag,
                is_on: true,
                logged_date: x.created_at,
                user: x.user,
              },
              {
                id: x.id,
                tag: x.tag,
                is_on: false,
                logged_date: x.updated_at,
                user: x.user,
              },
            );
          } else {
            logs.push({
              id: x.id,
              tag: x.tag,
              is_on: true,
              logged_date: x.created_at,
              user: x.user,
            });
          }
        }
      }
      setTagLogs(logs);
      setHarvestData(res.data);
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: translate(lang, res.data?.message ?? 'Something went wrong'),
        }),
      );
    }
  }, [params.harvestId]);

  const openImages = (images: string[]) => {
    setAsPhotoModalVisible(true);
    setAssessPhotos(
      images.map(image => {
        return {
          original: image,
          thumbnail: image,
        };
      }),
    );
  };
  const openInfo = (comment: any) => {
    setInfoShow(true);
    setInfoText(comment && comment.length > 0 ? comment : 'No comments yet');
  };

  const tableColumns = () => {
    if (!harvestData) return [];

    let result = seedColumns(
      harvestData.farm.type,
      harvestData.line.farming_method,
      lang,
    );
    result.splice(1, 0, {
      key: 'line',
      title: translate(lang, 'Farm/Line'),
      render: (x: ISeedingResource) => (
        <span>
          {`${farmsData.find(y => y.id === x.line.farm_id)?.name} - ${
            x.line.line_name
          }`}
        </span>
      ),
    });
    result.push(
      {
        key: 'comment',
        dataIndex: 'comment',
        title: translate(lang, 'Comment'),
        render: (x: any) => (
          <div
            className='btn__modal'
            onKeyDown={() => undefined}
            onClick={openInfo.bind(this, x)}
          >
            {translate(lang, 'View')}
          </div>
        ),
      },
      {
        key: 'images',
        dataIndex: 'images',
        title: translate(lang, 'Photo'),
        render: (x: any) =>
          !!x && x.length > 0 ? (
            <div className='btn__modal' onClick={openImages.bind(this, x)}>
              {translate(lang, 'View')}
            </div>
          ) : (
            <span></span>
          ),
      },
      {
        key: 'empty',
        title: '',
        render: (x: any) => <span></span>,
      },
    );
    return result;
  };

  const timelines = useMemo(() => {
    if (!harvestData) {
      return [];
    }
    let planned: any[] = [];
    for (let seed of harvestData.seedings as any) {
      const dates = seed.planned_date_history ?? [];
      for (let i = 0; i < dates.length - 1; i++) {
        if (dates[i] !== dates[i + 1]) {
          planned.push({
            id: `${seed.id} - ${i}`,
            old_date: dates[i],
            new_date: dates[i + 1],
          });
        }
      }
    }
    return [
      ...harvestData.seedings.map(x => ({
        type: 'SEEDING',
        data: x,
      })),
      ...harvestData.assessments.map(x => ({
        type: 'ASSESSMENT',
        data: x,
      })),
      ...harvestData.maintenances.map(x => ({
        type: 'MAINTENANCE',
        data: x,
      })),
      ...harvestData.inventory_seeds.map(x => ({
        type: 'INVENTORY_SEED',
        data: x,
      })),
      ...harvestData.harvests.map(x => ({
        type: 'HARVEST',
        data: x,
      })),
      ...tagLogs.map(x => ({
        type: 'TAG_LOG',
        data: x,
      })),
      ...planned.map((x: any) => ({ type: 'HARVEST_PLANNED', data: x })),
    ];
  }, [harvestData, tagLogs]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className='harvest-detail-page'>
      <div className='h-calc-80 bg-secondary'>
        <div className='container pos-relative mb-17'>
          <div className='pt-28 pb-28 d-flex justify-content-between'>
            <BreadcrumbComponent items={breadcrumbItems} />
            <div className='d-flex'>
              {!!harvestData?.pdf_form && (
                <Button
                  size={3}
                  type='fill'
                  width='small'
                  color='blue'
                  onClick={() => formDownload(harvestData.pdf_form)}
                >
                  {translate(lang, 'Download Harvest Declaration Form')}
                </Button>
              )}
              {!!harvestData?.harvests && harvestData.harvests.length > 0 && (
                <Button
                  className='ml-17'
                  size={3}
                  type='fill'
                  width='small'
                  color='blue'
                  onClick={() => setVisTrack(true)}
                >
                  {translate(lang, 'View Harvest Tracking')}
                </Button>
              )}
            </div>
          </div>
          {harvestData ? (
            <>
              {harvestData.farm.type === 'MUSSEL' ? (
                <MusselHarvestHeader
                  farm={harvestData.farm}
                  line={harvestData.line}
                  seedings={harvestData.seedings as IMusselSeeding[]}
                  harvests={harvestData.harvests as IMusselHarvest[]}
                  harvestID={Number(params.harvestId)}
                />
              ) : harvestData.farm.type === 'OYSTER' ? (
                <OysterHarvestHeader
                  farm={harvestData.farm}
                  line={harvestData.line}
                  seedings={harvestData.seedings as IOysterSeeding[]}
                  harvests={harvestData.harvests as IOysterHarvest[]}
                  harvestID={Number(params.harvestId)}
                />
              ) : (
                <SeaweedHarvestHeader
                  farm={harvestData.farm}
                  line={harvestData.line}
                  seedings={harvestData.seedings as ISeaweedSeeding[]}
                  harvests={harvestData.harvests as ISeaweedHarvest[]}
                />
              )}
              {timelines.length > 0 && (
                <TimelineView
                  type={harvestData.farm.type}
                  dataItems={timelines as any}
                />
              )}
              <div className='w-100 mt-10'>
                <div className='ant-table-wrapper table mt-17'>
                  <Title
                    className='ml-7'
                    size={6}
                    color='black-3'
                    align='default'
                    fontWeight={700}
                  >
                    {translate(lang, 'Seedings')}
                  </Title>
                  <Table
                    rowKey='id'
                    className='table table--isFarm pl-0 pr-0 overflow-auto'
                    pagination={false}
                    columns={tableColumns()}
                    dataSource={harvestData.seedings}
                  />
                </div>
              </div>
              <div className='w-100 mt-10'>
                <MaintenanceTable
                  type={harvestData.farm.type}
                  assessments={harvestData.assessments}
                  maintenances={harvestData.maintenances}
                  floats={harvestData.inventory_seeds}
                  withTitle={true}
                />
              </div>
              <div className='w-100 mt-10'>
                <HarvestsTable
                  harvests={harvestData.harvests}
                  onUpdateRow={() => loadData()}
                  type={harvestData.farm.type}
                  farmID={harvestData.farm.id}
                />
              </div>
              <ModalComponent
                visible={infoShow}
                onCancel={() => setInfoShow(false)}
                type=''
                title={translate(lang, 'Comment')}
                text={infoText}
              />
              {asPhotoModalVisible && (
                <Modal
                  title={translate(lang, 'Photos')}
                  centered
                  visible={asPhotoModalVisible}
                  onOk={() => setAsPhotoModalVisible(false)}
                  onCancel={() => setAsPhotoModalVisible(false)}
                  width={1000}
                >
                  <ReactImageGallery items={assessPhotos} />
                </Modal>
              )}
            </>
          ) : (
            <div className='d-flex align-items-center justify-content-center'>
              <Spinner />
            </div>
          )}
        </div>
      </div>
      {!!visTrack && !!harvestData?.harvests && (
        <HarvestTrackModal
          visible={visTrack}
          harvests={harvestData.harvests}
          onClose={() => setVisTrack(false)}
        />
      )}
    </div>
  );
};

export default HarvestDetailPage;
