import { NavLink, useHistory } from 'react-router-dom';
import { translate } from '../../lib/lang.helper';
import { useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { Spinner, Subtitle } from '../../components/shared';
import moment from 'moment';
import { formatNumber } from '../../entities/util-functions';
import { selectOysterFarms } from '../../store/farms/farms.selector';
import { useEffect, useState } from 'react';
import { Table } from 'antd';
import {
  defaultDateFormat,
  toMillisecond,
} from '../../util/toggleSecondMillisecond';
import { rangeLabel } from '../../util/farmUtil';
import { amountDays } from '../../lib/farm.helpers';
import { selectAccount } from '../../store/auth/auth.selector';
import { sendSingleRequest } from '../../apis';
import { ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';

const generateWeeklyRanges = () => {
  const weeks = [];
  const startDate = moment();
  for (let i = 0; i < 52; i++) {
    const startOfWeek = startDate.clone().startOf('isoWeek');
    const endOfWeek = startDate.clone().endOf('isoWeek');

    weeks.push({
      start_date: startOfWeek.toDate().getTime(),
      finish_date: endOfWeek.toDate().getTime(),
      day_label: `${startOfWeek.format('DD.MM.YYYY')} - ${endOfWeek.format(
        'DD.MM.YYYY',
      )}`,
      week_label: `Week ${i + 1}`,
    });
    startDate.add(1, 'weeks');
  }
  return weeks;
};

interface ILineProp {
  farm_id: number;
  line_id: number;
  harvest_size: number;
  grow_speed: number;
}

interface IRow {
  id: number | string;
  week: string;
  date: string;
  farm_id?: number;
  farm_name: string;
  farm_number: string;
  line_id?: number;
  line: string;
  farming_method: string;
  last_date: string;
  last_size: string;
  basket_count?: number;
  age: string;
  last_harvest: string;
  estimate_dz: string;
  rowSpan?: number;
  total?: number;
}

const columns = (lang: any) => [
  {
    key: 'week',
    render: (x: IRow) =>
      x.rowSpan ? (
        <span>
          <div className='text-center'>{x.week}</div>
          <div>{x.date}</div>
        </span>
      ) : (
        ''
      ),
    width: 210,
  },
  {
    title: translate(lang, 'Farm'),
    key: 'farm',
    render: (x: IRow) => (
      <span>
        <div>{x.farm_name}</div>
        <div className='text-center'>{x.farm_number}</div>
      </span>
    ),
  },
  {
    title: translate(lang, 'Line'),
    key: 'line',
    render: (x: IRow) => <span>{x.line}</span>,
    width: 80,
  },
  {
    title: translate(lang, 'Last assessment date'),
    key: 'last_date',
    render: (x: IRow) => <span>{x.last_date}</span>,
  },
  {
    title: translate(lang, 'Last known size'),
    key: 'last_size',
    render: (x: IRow) => <span>{x.last_size}</span>,
  },
  {
    title: translate(lang, 'Number of containers'),
    key: 'line_length',
    render: (x: IRow) =>
      x.basket_count ? (
        <span>{`${x.basket_count} ${x.farming_method}`}</span>
      ) : (
        <></>
      ),
  },
  {
    title: translate(lang, "Today's age"),
    key: 'age',
    render: (x: IRow) => <span>{x.age}</span>,
  },
  {
    title: translate(lang, 'Previous harvest'),
    key: 'last_harvest',
    render: (x: IRow) => <span>{x.last_harvest}</span>,
  },
  {
    title: translate(lang, 'Estimated harvest'),
    key: 'estimate_harvest',
    render: (x: IRow) => <span>{x.estimate_dz}</span>,
  },
  {
    title: translate(lang, 'Total'),
    key: 'total',
    render: (x: IRow) => (
      <span>{x.total ? `${formatNumber(x.total)} dz` : ''}</span>
    ),
  },
];

const OysterCalenderPage = () => {
  const history = useHistory();
  const lang = useSelector(selectLang);
  const farmsData = useSelector(selectOysterFarms);
  const accountSetting = useSelector(selectAccount);

  const [rowsData, setRowsData] = useState<IRow[]>();
  const [chartData, setChartData] =
    useState<ChartData<'bar', number[], string>>();

  const totalSum = rowsData?.reduce((p, c) => p + (c.total ?? 0), 0) ?? 0;

  useEffect(() => {
    (async () => {
      const response = await sendSingleRequest(
        {},
        'GET',
        'api/farm/lines-props',
        true,
      );
      if (!response.status) {
        window.alert(response.data?.message ?? 'Server error');
        return;
      }
      const linesProps: ILineProp[] = response.data;
      const weekRanges = generateWeeklyRanges();
      const harvestSize = accountSetting?.harvest_min_size,
        growSpeed = accountSetting?.grow_speed;
      let result: IRow[] = [];
      let chartValues: number[] = [];
      let chartLabels: string[] = [];
      for (const week of weekRanges) {
        let first = 0,
          cnt = 0,
          totalSum = 0;
        for (const farm of farmsData) {
          for (const line of farm.lines) {
            const cycle = line.growing_cycle;
            if (!cycle) continue;

            const prop = linesProps.find(
              x => x.farm_id === farm.id && x.line_id === line.id,
            );
            if (!prop && (!harvestSize || !growSpeed)) continue;

            const size = prop?.harvest_size ?? harvestSize ?? 0;
            const speed = prop?.grow_speed ?? growSpeed ?? 0;
            let pd: number;
            if (cycle.last_assessment) {
              const ds = Math.max(
                size - cycle.last_assessment.shell_size.avg,
                0,
              );
              const dt = (ds / speed) * 30 * 86400000;
              pd = toMillisecond(cycle.last_assessment.assessment_date) + dt;
            } else {
              const avgSize = cycle.main_seed.spat_size_max
                ? (cycle.main_seed.spat_size + cycle.main_seed.spat_size_max) /
                  2
                : cycle.main_seed.spat_size;
              const ds = Math.max(size - avgSize, 0);
              const dt = (ds / speed) * 30 * 86400000;
              pd = toMillisecond(cycle.main_seed.planned_date_seed) + dt;
            }
            if (week.start_date <= pd && pd <= week.finish_date) {
              const id = result.length + 1;
              const basketCount =
                cycle.total_seeded_basket_count -
                cycle.total_harvested_basket_count;
              const etaDozens = cycle.last_assessment?.estimated_amount
                ? cycle.last_assessment?.estimated_amount / 12
                : cycle.total_seeded_dozens - cycle.total_harvested_dozens;
              result.push({
                id: id,
                week: week.week_label,
                date: week.day_label,
                farm_id: farm.id,
                line_id: line.id,
                farm_name: farm.name,
                farm_number: farm.farm_number,
                line: line.line_name,
                last_date:
                  defaultDateFormat(cycle.last_assessment?.assessment_date) ??
                  '-',
                last_size: cycle.last_assessment
                  ? `${cycle.last_assessment.shell_size.avg} mm`
                  : rangeLabel(
                      cycle.main_seed.spat_size,
                      cycle.main_seed.spat_size_max,
                      'mm',
                    ),
                basket_count: basketCount,
                farming_method: line.farming_method ?? 'baskets',
                age: amountDays(
                  moment().diff(
                    toMillisecond(cycle.main_seed.planned_date_seed),
                    'days',
                  ),
                ),
                last_harvest: line?.last_final_harvest
                  ? `${formatNumber(line.last_final_harvest.amount / 12)} dz${
                      line.last_final_harvest.line_length
                        ? ` (${formatNumber(
                            line.last_final_harvest.line_length,
                          )} ${line.farming_method ?? 'baskets'})`
                        : ''
                    }`
                  : '-',
                estimate_dz: `${formatNumber(etaDozens)} dz`,
              });
              if (!first) first = id;
              totalSum += etaDozens;
              cnt++;
            }
          }
        }
        if (first) {
          let i = result.findIndex(x => x.id === first);
          result[i].rowSpan = cnt;
          result[result.length - 1].total = totalSum;
        } else {
          result.push({
            id: result.length + 1,
            week: week.week_label,
            date: week.day_label,
            farm_name: '',
            farm_number: '',
            line: '',
            last_date: '',
            last_size: '',
            basket_count: undefined,
            age: '',
            last_harvest: '',
            estimate_dz: '',
            farming_method: '',
            rowSpan: 1,
          });
        }
        if (totalSum > 0) {
          chartValues.push(totalSum);
          chartLabels.push(week.week_label);
        }
      }
      setRowsData(result);
      setChartData({
        labels: chartLabels,
        datasets: [
          {
            data: chartValues,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
            label: translate(lang, 'Estimated Harvest'),
          },
        ],
      });
    })();
  }, []);

  return (
    <div className='bg-secondary'>
      <div
        className='container performance-page pb-32'
        style={{ maxWidth: 1600 }}
      >
        <div className='sub-header'>
          <NavLink className='header__link' to={'/oyster-summary/estimate'}>
            {translate(lang, 'Harvest Delivery Estimates')}
          </NavLink>
          <NavLink
            className='header__link ml-24 mr-24'
            to={'/oyster-summary/calender'}
          >
            {translate(lang, 'Calendar Estimates')}
          </NavLink>
          <NavLink className='header__link mr-24' to={'/oyster-summary/growth'}>
            {translate(lang, 'Growth Calculator')}
          </NavLink>
          <NavLink className='header__link' to={'/oyster-summary/lines'}>
            {translate(lang, 'Size Group')}
          </NavLink>
        </div>
        {rowsData === undefined ? (
          <div className='loader'>
            <Spinner />
          </div>
        ) : (
          <div className='content pb-32'>
            {chartData !== undefined && (
              <div className='mb-24 pr-16 pl-16 pt-16 pb-16'>
                <Bar
                  data={chartData}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                    scales: {
                      x: {
                        beginAtZero: true,
                      },
                    },
                  }}
                  height={70}
                />
              </div>
            )}
            <Table
              rowKey={'id'}
              columns={columns(lang)}
              dataSource={rowsData}
              pagination={false}
              onRow={row => ({
                onClick: () =>
                  row.farm_id && row.line
                    ? history.push(`/farms/${row.farm_id}/${row.line_id}`)
                    : undefined,
                style: { cursor: 'pointer' },
              })}
              scroll={{ y: 600 }}
            />
            <div className='mr-32 mt-16 mb-7'>
              <Subtitle size={4} color='black' align='right' fontWeight={500}>
                {`${translate(lang, 'Total')}: ${formatNumber(totalSum)} dz`}
              </Subtitle>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OysterCalenderPage;
