import { useCallback, useEffect, useState } from 'react';
import { Table } from 'antd';
import { sendSingleRequest } from '../../apis';
import { Button, Spinner, Title } from '../../components/shared';
import './styles.scss';

interface IUser {
  id: number;
  name: string | null;
  email: string;
}

interface IAccount {
  id: number;
  users: IUser[];
  owner: IUser | null;
  is_freemium: boolean;
  created_at: string | null;
}

const columns = [
  {
    title: 'ID',
    key: 'id',
    render: (x: IAccount) => <span>{x.id}</span>,
  },
  {
    title: 'Owner',
    key: 'owner',
    render: (x: IAccount) => (
      <span>{x.owner ? `${x.owner.email} - ${x.owner.name}` : ''}</span>
    ),
  },
  {
    title: 'Users',
    key: 'users',
    render: (x: IAccount) => (
      <span>
        {x.users.map(u => (
          <div key={u.id}>{`${u.email} - ${u.name}`}</div>
        ))}
      </span>
    ),
  },
  {
    title: 'Created at',
    key: 'created_at',
    render: (x: IAccount) => <span>{x.created_at}</span>,
  },
  {
    title: 'Freemium',
    key: 'is_freemium',
    render: (x: IAccount) => <span>{x.is_freemium ? 'Freemium' : ''}</span>,
  },
];

const AdminAccountsPage = () => {
  const [accounts, setAccounts] = useState<IAccount[]>();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const loadUsers = useCallback(async () => {
    setLoading(true);
    const res = await sendSingleRequest({}, 'GET', 'api/admin/accounts', true);
    if (res.status) {
      setAccounts(res.data);
    } else {
      window.alert(res.data?.message ?? 'Something went wrong');
    }
    setLoading(false);
  }, []);

  const deleteClick = async (acc: IAccount) => {
    if (
      !window.confirm(
        'This account and all its users will be deleted now. Please make sure you download a backup Excel of data just in case if user would like to access the data in the future.',
      )
    )
      return;
    setDisabled(true);
    const res = await sendSingleRequest(
      { account_id: acc.id },
      'DELETE',
      'api/admin/account',
      true,
    );
    setDisabled(false);
    if (res.status) {
      await loadUsers();
    } else {
      window.alert(res.data?.message ?? 'Something went wrong');
    }
  };

  const tableColumns = [
    ...columns,
    {
      title: 'Action',
      key: 'action',
      render: (x: IAccount) => (
        <Button
          color='red'
          size={0}
          width='small'
          type='fill'
          disabled={disabled}
          onClick={() => deleteClick(x)}
        >
          Delete
        </Button>
      ),
    },
    { title: '', key: 'right', render: () => <span></span> },
  ];

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  return (
    <div className='bg-secondary min-height'>
      <div className='container'>
        <div className='queued-page-header justify-content-between align-items-center'>
          <Title size={5} color='black' align='default' fontWeight={700}>
            All Accounts
          </Title>
        </div>
        <div className='w-100 mt-17 pb-32'>
          {loading ? (
            <div className='d-flex justify-content-center align-items-center min-height'>
              <Spinner />
            </div>
          ) : (
            <Table
              rowKey='id'
              className='table table--isFarm'
              pagination={false}
              columns={tableColumns}
              dataSource={accounts}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminAccountsPage;
