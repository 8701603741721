import { useState, useMemo, useEffect } from 'react';
import { Modal } from 'antd';
import {
  ILineResource,
  IMaintenanceResource,
} from '../../entities/farms.entities';
import {
  Button,
  CloseIcon,
  Datepicker,
  Dropdown,
  Input,
  PlusIcon,
  Subtitle,
  TrashIcon,
} from '../shared';
import ModalFeedbackView from '../shared/feedback/ModalFeedbackView';
import { useDispatch, useSelector } from 'react-redux';
import { showFeedback } from '../../store/ui/ui.actions';
import { sendSingleRequest } from '../../apis';
import { loadFarmsData } from '../../store/farms/farms.actions';
import GrowerInvoiceCreateView from '../budget/GrowerInvoiceCreateView';
import { IXeroInvoice } from '../../entities/subscription.entities';
import InventoryModal from '../../pages/Inventories/InventoryModal';
import moment from 'moment';
import { updateInventoryQuantity } from '../../store/inventories/inventories.actions';
import { toMillisecond, toSecond } from '../../util/toggleSecondMillisecond';
import ExpensesForm from '../grower-views/ExpensesForm';
import { IExpenseRequest } from '../../entities/budget.entities';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import {
  SelectInventoriesByFarmID,
  SelectIsEditable,
  SelectMusselFarmsByFarmID,
} from '../../store/extra/extra.selector';
import './styles.scss';

const typeOptions = [
  {
    id: 'VISUAL_ASSESSMENT',
    value: 'VISUAL_ASSESSMENT',
    label: 'Visual Assessment',
  },
  { id: 'LINE_CLEANING', value: 'LINE_CLEANING', label: 'Line Cleaning' },
  {
    id: 'GENERAL_MAINTENANCE',
    value: 'GENERAL_MAINTENANCE',
    label: 'General Maintenance',
  },
];

const outInOptions = [
  { id: 'IN', value: 'IN', label: 'Remove from line to inventory' },
  { id: 'OUT', value: 'OUT', label: 'Add to the line' },
];

type typeField = 'VISUAL_ASSESSMENT' | 'LINE_CLEANING' | 'GENERAL_MAINTENANCE';

interface IIvtItem {
  inventory_id: number | null;
  type: 'IN' | 'OUT';
  quantity: number | null;
  unit_price: number | null;
}

interface IForm {
  maintain_date: number;
  type: typeField;
  comment: string | null;
}

const defaultForm: IForm = {
  maintain_date: moment().toDate().getTime(),
  type: 'GENERAL_MAINTENANCE',
  comment: null,
};

interface Props {
  visible: boolean;
  maintenanceData?: IMaintenanceResource;
  lineData?: ILineResource;
  onClose: (d: any) => void;
  onConfirm: (d: any) => void;
  onlyView?: boolean;
  updateId?: number;
  data?: any;
  overConfirm?: (d: any) => void;
}

const GrowerMaintenanceModal = ({
  visible,
  onlyView,
  maintenanceData,
  updateId,
  lineData,
  onClose,
  onConfirm,
  data: paramData,
  overConfirm,
}: Props) => {
  const dispatch = useDispatch<any>();

  const lang = useSelector(selectLang);
  const farmsData = SelectMusselFarmsByFarmID(lineData?.farm_id);
  const inventories = SelectInventoriesByFarmID(lineData?.farm_id);
  const isEditable = SelectIsEditable(lineData?.farm_id);

  const growingLines = useMemo(() => {
    let res: ILineResource[] = [];
    for (let f of farmsData) {
      res = [...res, ...f.lines];
    }
    return res;
  }, [farmsData]);

  const [curLine, setCurLine] = useState(lineData);
  const [form, setForm] = useState<IForm>(
    maintenanceData
      ? {
          ...defaultForm,
          ...maintenanceData,
          maintain_date: 1000 * maintenanceData.maintain_date,
        }
      : defaultForm,
  );
  const [disabled, setDisabled] = useState(false);
  const [expenses, setExpenses] = useState<IExpenseRequest[]>(
    maintenanceData?.expense_items?.map(x => ({
      name: x.name,
      quantity: x.quantity,
      unit_price: Math.round((x.amount / (x.quantity ?? 1)) * 100) / 100,
      amount: x.amount,
    })) ?? [],
  );
  const [submittedData, setSubmittedData] = useState<any>();
  const [showConfirm, setShowConfirm] = useState(false);
  const [ivtItems, setIvtItems] = useState<IIvtItem[]>([]);
  const [showIvtModal, setShowIvtModal] = useState(false);

  const showError = (message: string) =>
    dispatch(
      showFeedback({
        isMessageModal: true,
        type: 'error',
        message: translate(lang, message),
      }),
    );
  const selectLine = (v: string) => {
    const line = growingLines.find(x => x.id === Number(v));
    if (line) {
      setCurLine(line);
      if (!line.growing_cycle) setIvtItems([]);
    }
  };
  const updateForm = (key: keyof IForm, val: any) =>
    setForm({ ...form, [key]: val });
  const addFloatClick = () => {
    let tmp = [...ivtItems];
    tmp.push({
      inventory_id: null,
      type: 'OUT',
      quantity: null,
      unit_price: null,
    });
    setIvtItems(tmp);
  };
  const updateIvtItem = (i: number, key: keyof IIvtItem, val: any) => {
    let tmp: any = [...ivtItems];
    tmp[i][key] = val;
    if (key === 'inventory_id' && val === 0) {
      tmp.type = 'OUT';
    }
    setIvtItems(tmp);
  };
  const deleteIvtItem = (i: number) => {
    const tmp = ivtItems.filter((x, j) => i !== j);
    setIvtItems(tmp);
  };
  const invoiceDetail = (): IXeroInvoice => {
    const line_items = [
      ...expenses.map(x => ({
        item_name: `${curLine?.farm_name} - ${curLine?.line_name}`,
        description: x.name,
        quantity: Number(x.quantity),
        unit_price: Number(x.unit_price),
      })),
      ...ivtItems.map(x => {
        const v = inventories.find(t => t.id === x.inventory_id);
        return {
          item_name: `${curLine?.farm_name} - ${curLine?.line_name}`,
          description: `${v?.type} - ${v?.subtype}`,
          quantity: Number(x.quantity),
          unit_price: Number(x.unit_price),
        };
      }),
    ];
    return {
      reference: 'Maintenance Invoice',
      contact_id: '',
      line_items,
      type: 'ACCPAY',
    };
  };
  const validData = async () => {
    if (!curLine) {
      showError('Please select line');
      return null;
    }
    if (!form.comment) {
      showError('Please enter comment');
      return null;
    }
    if (expenses.some(x => !x.name)) {
      showError('Please enter expense item description');
      return null;
    }
    if (expenses.some(x => !x.quantity)) {
      showError('Please enter valid expense quantity');
      return null;
    }
    if (expenses.some(x => !x.unit_price)) {
      showError('Please enter valid expense unit price');
      return null;
    }
    if (!curLine.growing_cycle && ivtItems.length > 0) {
      showError('Impossible to enter expensed inventories on empty line');
      return null;
    }
    let flag = false;
    for (let i = 0; i < ivtItems.length; i++) {
      for (let j = i + 1; j < ivtItems.length; j++) {
        if (ivtItems[i].inventory_id === ivtItems[j].inventory_id) {
          flag = true;
          break;
        }
      }
      if (flag) break;
    }
    if (flag) {
      showError('You selected same inventory for one manage');
      return false;
    }
    for (let x of ivtItems) {
      if (!x.inventory_id) {
        showError('Please select inventory');
        return false;
      }
      if (!x.quantity) {
        showError('Please enter valid quantity');
        return false;
      }
      if (!x.unit_price) {
        showError('Please enter valid unit price');
        return false;
      }
      const v = inventories.find(y => y.id === x.inventory_id);
      if (!v) {
        showError('Inventory not found');
        return false;
      }
      const mx =
        x.type === 'OUT'
          ? v.available_quantity
          : curLine?.growing_cycle?.inventories_sum.find(
              t => t.inventory_id === x.inventory_id,
            )?.quantity ?? 0;
      if (x.quantity > mx) {
        if (x.type === 'IN') {
          showError(
            translate(
              lang,
              'Maximum available value for %s is %s',
              v.subtype ?? '',
              mx,
            ),
          );
          return false;
        }

        const cf = window.confirm(
          `You didn't have enough ${x.quantity} (${
            v.subtype
          }) - the system has total ${v.quantity} and ${
            v.available_quantity
          } are available. Do you want ${x.quantity - mx} more?`,
        );
        if (!cf) return false;

        setDisabled(true);
        const r = await dispatch(
          updateInventoryQuantity(v.id, v.quantity + x.quantity - mx),
        );
        setDisabled(false);
        if (r !== true) {
          showError(r);
          return false;
        }
      }
    }
    let result: any = {
      line_id: curLine.id,
      ...form,
      maintain_date: toSecond(form.maintain_date),
      expenses: [
        ...expenses.map(x => ({
          ...x,
          amount: Number(x.quantity) * Number(x.unit_price),
        })),
        ...ivtItems.map(x => {
          const v = inventories.find(t => t.id === x.inventory_id);
          return {
            name: `${v?.type} - ${v?.subtype}`,
            quantity: x.quantity,
            unit_price: x.unit_price,
            amount: Number(x.quantity) * Number(x.unit_price),
          };
        }),
      ],
    };
    if (curLine.growing_cycle) {
      result.seeding_id = curLine.growing_cycle.main_seed.id;
    }
    return result;
  };
  const submitFloats = async () => {
    const seedID = curLine?.growing_cycle?.main_seed.id;
    if (!seedID) {
      alert(
        translate(
          lang,
          'Impossible to enter expensed inventories on empty line',
        ),
      );
      return;
    }
    for (let x of ivtItems) {
      const res = await sendSingleRequest(
        {
          inventory_id: x.inventory_id,
          quantity: x.quantity,
          type: x.type,
          manage_date: toSecond(form.maintain_date),
        },
        'POST',
        `api/farm/line/seed/inventory-seeds/${seedID}`,
        true,
      );
      if (!res.status) {
        alert(translate(lang, res.data?.message ?? 'Unknown error'));
      }
    }
  };
  const confirmClick = async () => {
    const data = await validData();
    if (!data || onlyView) return;

    if (!showConfirm) {
      setShowConfirm(true);
      return;
    }

    setDisabled(true);
    if (overConfirm) {
      overConfirm(data);
      return;
    }
    await submitFloats();
    const res = !updateId
      ? await sendSingleRequest(
          data,
          'POST',
          'api/farm/line/seed/maintenance',
          true,
        )
      : await sendSingleRequest(
          data,
          'PUT',
          `api/farm/line/seed/maintenance/${updateId}`,
          true,
        );
    setDisabled(false);
    if (res.status) {
      dispatch(
        showFeedback({
          isMessageModal: true,
          message: translate(lang, res.data?.message ?? 'Success'),
          type: 'success',
        }),
      );
      setSubmittedData(res.data);
      dispatch(loadFarmsData());
      onConfirm(res.data);
    } else {
      showError(res.data?.message ?? 'Failed to enter maintenance data');
    }
  };

  useEffect(() => {
    if (paramData) {
      const { expenses, maintain_date, ...params } = paramData;
      setForm({
        ...form,
        ...params,
        maintain_date: toMillisecond(maintain_date),
      });
      setExpenses(expenses ?? []);
    }
  }, [paramData]);

  return showIvtModal ? (
    <InventoryModal
      visible={true}
      title={translate(lang, 'Add new inventory')}
      data={null}
      onConfirm={() => setShowIvtModal(false)}
      onCancel={() => setShowIvtModal(false)}
    />
  ) : (
    <Modal
      visible={visible}
      onCancel={onClose}
      closable
      maskClosable={false}
      closeIcon={<CloseIcon />}
      width={1200}
      footer={null}
    >
      {!showConfirm ? (
        <div className='grower-seed-modal wrap'>
          <div className='d-flex align-items-center mb-17'>
            <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
              {translate(lang, 'INVOICE DETAILS')}
            </Subtitle>
          </div>
          <div className='section'>
            {!updateId && (
              <div className='mb-17'>
                <Dropdown
                  label={translate(lang, 'Select line')}
                  options={growingLines.map(x => ({
                    id: x.id.toString(),
                    value: x.id.toString(),
                    label: `${x.farm_name} - ${x.line_name}`,
                  }))}
                  value={curLine?.id.toString()}
                  onChange={v => selectLine(v)}
                  disabled={disabled}
                />
              </div>
            )}
            <div className='mb-17'>
              <Datepicker
                label={translate(lang, 'Maintained date')}
                defaultValue={form.maintain_date}
                onChange={e =>
                  e && updateForm('maintain_date', e.toDate().getTime())
                }
              />
            </div>
            <div className='mb-17'>
              <Dropdown
                label={translate(lang, 'Type')}
                placeholder={translate(lang, 'Type')}
                options={typeOptions}
                value={form.type}
                onChange={(val, evt) => updateForm('type', val)}
                disabled={disabled}
              />
            </div>
            <div className='mb-17'>
              <Input
                label={translate(lang, 'Comment')}
                placeholder={translate(lang, 'Type your comment%s', '...')}
                type='textarea'
                value={form.comment ?? ''}
                dataType='comment'
                onChange={e => updateForm('comment', e.target.value)}
                disabled={disabled}
              />
            </div>
          </div>
          <div className='section'>
            {(!curLine || !!curLine?.growing_cycle) && !updateId && (
              <>
                {isEditable && (
                  <div className='mb-17'>
                    <Button
                      width='full'
                      size={2}
                      type='fill'
                      color='blue'
                      onClick={() => setShowIvtModal(true)}
                    >
                      {translate(lang, 'Add New Inventory')}
                    </Button>
                  </div>
                )}
                <div className='mb-7'>
                  <Subtitle
                    color='black-2'
                    align='left'
                    size={5}
                    fontWeight={500}
                  >
                    {translate(lang, 'Expense inventory')}
                  </Subtitle>
                </div>
                <div className='mb-17'>
                  <div className='item-row'>
                    <div className='item-cell'>{translate(lang, 'Name')}</div>
                    <div className='item-cell'>
                      {`${translate(lang, 'Put In')}/${translate(
                        lang,
                        'Take Out',
                      )}`}
                    </div>
                    <div className='item-cell'>
                      {translate(lang, 'Quantity')}
                    </div>
                    <div className='item-cell'>
                      {translate(lang, 'Unit price')}
                    </div>
                    <div className='item-cell'>{translate(lang, 'Amount')}</div>
                    <div className='item-cell-half'></div>
                  </div>
                  {ivtItems.map((x, i) => (
                    <div className='item-row' key={i}>
                      <div className='item-cell'>
                        <Dropdown
                          label=''
                          options={inventories.map(y => ({
                            id: y.id.toString(),
                            value: y.id.toString(),
                            label: `${y.type} - ${y.subtype}`,
                          }))}
                          value={x.inventory_id?.toString()}
                          onChange={v =>
                            updateIvtItem(i, 'inventory_id', Number(v))
                          }
                        />
                      </div>
                      <div className='item-cell' style={{ maxWidth: '195px' }}>
                        <Dropdown
                          label=''
                          options={outInOptions}
                          value={x.type}
                          onChange={v => updateIvtItem(i, 'type', v)}
                        />
                      </div>
                      <div className='item-cell'>
                        <Input
                          type='number'
                          label=''
                          value={x.quantity?.toString() ?? ''}
                          onChange={e =>
                            updateIvtItem(
                              i,
                              'quantity',
                              e.target.value.length <= 0
                                ? null
                                : Number(e.target.value),
                            )
                          }
                        />
                      </div>
                      <div className='item-cell'>
                        <Input
                          type='number'
                          label=''
                          value={x.unit_price?.toString() ?? ''}
                          onChange={e =>
                            updateIvtItem(
                              i,
                              'unit_price',
                              e.target.value.length <= 0
                                ? null
                                : Number(e.target.value),
                            )
                          }
                        />
                      </div>
                      <div className='item-cell'>
                        <Input
                          type='number'
                          label=''
                          value={
                            x.unit_price && x.quantity
                              ? (x.quantity * x.unit_price).toFixed(2)
                              : ''
                          }
                        />
                      </div>
                      <div className='item-cell-half'>
                        <Button
                          width='full'
                          size={0}
                          type='bordered'
                          color='red'
                          className='d-flex justify-content-center'
                          onClick={deleteIvtItem.bind(this, i)}
                        >
                          <TrashIcon color='#cc0101' />
                        </Button>
                      </div>
                    </div>
                  ))}
                  <div className='item-row'>
                    <div className='item-cell'>
                      <Button
                        width='full'
                        size={5}
                        type='bordered'
                        color='blue'
                        className='d-flex justify-content-center'
                        onClick={addFloatClick}
                      >
                        <PlusIcon />
                      </Button>
                    </div>
                    <div className='item-cell'></div>
                    <div className='item-cell'></div>
                    <div className='item-cell'></div>
                    <div className='item-cell'></div>
                    <div className='item-cell'></div>
                    <div className='item-cell-half'></div>
                  </div>
                </div>
              </>
            )}
            <ExpensesForm
              title={translate(lang, 'Expense non inventory items')}
              data={expenses}
              setData={setExpenses}
              additionalQuantity={ivtItems.reduce(
                (p, c) => p + Number(c.quantity),
                0,
              )}
              additionalAmount={ivtItems.reduce(
                (p, c) => p + Number(c.quantity) * Number(c.unit_price),
                0,
              )}
            />
          </div>
          <div className='modal-button d-flex justify-content-end align-items-center'>
            <Button
              width='small'
              size={2}
              type='fill'
              color='red'
              className='rsp-btn'
              onClick={onClose}
              disabled={disabled}
            >
              {translate(lang, 'Cancel')}
            </Button>
            <Button
              width='small'
              size={2}
              type='fill'
              color='green'
              className='rsp-btn ml-16'
              onClick={confirmClick}
              disabled={disabled || onlyView}
            >
              {translate(lang, 'Confirm')}
            </Button>
          </div>
        </div>
      ) : (
        <div className='grower-seed-modal wrap'>
          <GrowerInvoiceCreateView
            farmID={lineData?.farm_id}
            invoice={invoiceDetail()}
            onClose={() => onConfirm(submittedData)}
            showButton={!!submittedData}
          />
          {!submittedData && (
            <div className='modal-button d-flex justify-content-end align-items-center'>
              <Button
                width='small'
                className='rsp-btn'
                size={2}
                type='fill'
                color='red'
                onClick={() => setShowConfirm(false)}
                disabled={disabled}
              >
                {translate(lang, 'Go Back')}
              </Button>
              <Button
                width='small'
                size={2}
                type='fill'
                color='green'
                className='rsp-btn ml-16'
                onClick={confirmClick}
                disabled={disabled}
              >
                {translate(lang, 'Confirm')}
              </Button>
            </div>
          )}
        </div>
      )}
      <ModalFeedbackView />
    </Modal>
  );
};

export default GrowerMaintenanceModal;
